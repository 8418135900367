import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import swal from "sweetalert2";
import { Router } from "@angular/router";

// import { generalModel, icons, menu, BtnText, permission, tabArray, optionArray } from '../shared/models/general.model';
import { Subject } from "rxjs";
import { apiUrl } from "../global/global";
import { DataSharingService } from "./data.share.service";

import * as lpn from 'google-libphonenumber';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Injectable({
  providedIn: "root",
})
export class AdminService {

  apiEndpoint: string = "";
  showLoader: boolean = true;
  access_token: any;
  dropdownData: any = undefined;
  language1:any
  // icon = icons;
  // menu = menu;
  // btnText = BtnText;
  // generalModel = new generalModel();
  // Permission = permission;
  public _albums = [];
  phoneUtil:any
  
  PhoneNumberFormat = PhoneNumberFormat;
  constructor(private http: HttpClient, private router: Router, private dataSharingService: DataSharingService) {
    this.language1= localStorage.getItem("language");
    this.apiEndpoint = environment.apiUrl;
    this.access_token = localStorage.getItem("token");
    
    this.phoneUtil = lpn.PhoneNumberUtil.getInstance();
  }


  laguageSet() {}

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  getDatanew(url, option) {
    return this.http.get<any>(this.apiEndpoint + url, option);
  }

  getData(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }
  getDatas(url) {
    return this.http.get<any>(this.apiEndpoint + url);
  }

  getDetailByidParams(data, url, param = {}) {
    return this.http.get<any>(this.apiEndpoint + url + data.id, {
      params: param,
    });
  }
  getDetail(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }

  getDetail1(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }
  getDetailByid(data, url) {
    return this.http.get<any>(this.apiEndpoint + url + data.id, {});
  }
  putData(url, param) {
    return this.http.put<any>(this.apiEndpoint + url, param);
  }

  putDataWithoutLoader(url, param) {
    this.showLoader = false;
    return this.http.put<any>(this.apiEndpoint + url, param);
  }

  postData(url, value) {
    return this.http.post<any>(this.apiEndpoint + url, value);
  }
  postDataWithoutLoader(url, value) {
    this.showLoader=false;
    return this.http.post<any>(this.apiEndpoint + url, value);
  }

  httpOptions1 = {
    headers: new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    ),
  };
  postDataNew(url, value) {
    return this.http.post<any>(
      this.apiEndpoint + url,
      value.toString(),
      this.httpOptions1
    );
  }
  putDataNew(url, value) {
    return this.http.put<any>(
      this.apiEndpoint + url,
      value.toString(),
      this.httpOptions1
    );
  }

  upateData(url, value) {
    return this.http.put<any>(this.apiEndpoint + url, value);
  }
  patchUpdate(url, value) {
    return this.http.patch<any>(this.apiEndpoint + url, value);
  }
  postUpdate(url, value) {
    return this.http.put<any>(this.apiEndpoint + url, value);
  }

  postNewData(url, key, value) {
    return this.http.post<any>(this.apiEndpoint + url, key, value);
  }
  updateData(url, value) {
    return this.http.patch<any>(this.apiEndpoint + url, value);
  }

  getDataWithoutLoader(url: any, param: any) {
    this.showLoader = false;
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }

  // deleteData(url, value) {
  //   return this.http.delete<any>(this.apiEndpoint +url, value);
  // }

  deleteData(url, param) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: param,
    };
    return this.http.delete<any>(this.apiEndpoint + url, options);
  }

  showAlert(title: string, text: string) {
    swal.fire({
      title: '<figure><img src="../../assets/images/sucess.svg"></figure>',
      text: text,
      showConfirmButton: true,
      confirmButtonText: this.language1 == 2 ? 'Ok' : this.language1 == 1 ? 'Buena' : 'Ok', 
      confirmButtonColor: "#2b3991",
      timer: 1500,
    });
  }

  showError(title: string, text: string, confirmButtonText: string) {
    swal.fire({
      title: title,
      text: text,
      showConfirmButton: false,
      confirmButtonColor: "#2b3991",
      timer: 8000,
    });
  }

  errorAlert(text, status, languageSet) {
    // alert(languageSet)
    swal
      .fire({
        title: languageSet == 2 ? 'Alert' : languageSet == 1 ? 'Alerta' : 'Alerta',
        text: text,
        customClass: {popup: 'swal-Alerts'},
        showConfirmButton: true,
        confirmButtonText: languageSet == 2 ? 'Got it' : languageSet == 1 ? 'Entendido' : 'peguei', 
      })
      .then((result) => {
       
        if (status == 401 && text == 'Invalid forgot password token.') {
          // this.router.navigate(["/buy-plan"]);
          sessionStorage.clear()
          this.router.navigate(["/login"]);
        } else {
          // console.log('check2')
          // this.dataSharingService.clear();
          // this.router.navigate(["/login"]);
        }
      });
  }


  errorAlertmsg(text, status) {
    swal
      .fire({
        title: this.language1 == 2 ? 'Alert' : this.language1 == 1 ? 'Alerta' : 'Alerta',
        text: text,
        customClass: {popup: "swal-Alerts"}
      })
      .then((result) => {



      });
  }

  // Show and hide Loader
  private loaderSubject = new Subject<Boolean>();
  loaderState = this.loaderSubject.asObservable();

  loader(value) {
    this.loaderSubject.next(value);
  }

  logOut() {
    this.language1= localStorage.getItem("language");
    swal
      .fire({
        icon: "warning",
        text: this.language1== 2 ? "Are you sure you want to logout ?" : this.language1== 1 ? '¿Estás segura de que quieres cerrar sesión?' :'Tem certeza que deseja sair ?',
        showCancelButton: true,
        confirmButtonColor: "#2b3991",
        cancelButtonColor: "#939393",
        confirmButtonText: this.language1== 2 ? "Yes" : this.language1== 1 ? 'Sí' :'Sim',
        cancelButtonText: this.language1== 2 ? "Cancel" : this.language1== 1 ? 'Cancelar' :'Cancelar',
      })
      .then((result) => {
        if (result.value) {
          const data = {
            deviceToken: localStorage.getItem("deviceToken") ? localStorage.getItem("deviceToken") : '0123456789123456',
          }
          // this.putData(apiUrl._logOut,data).subscribe((res)=>{
            this.putData(apiUrl._logout, data).subscribe(
              async (res) => {
                // localStorage.clear();
          this.dataSharingService.clear();
          this.router.navigate(["/login"]);
          })
        }
      });
  }

  expiedPlan() {
    swal
      .fire({
        icon: "warning",
        text: "Your plan has expired. Please purchase a license to continue using .",
        showCancelButton: true,
        confirmButtonColor: "#2b3991",
        confirmButtonText: "Buy plan",
      })
      .then((result) => {
        if (result.value) {
          this.router.navigate(["/plan/buy"]);
        } else {
          this.dataSharingService.clear();
          this.router.navigate(["/login"]);
        }
      });
  }

  setDropdownData(data: any) {
    // console.log('dataaaaaa', data)
    this.dropdownData = data;
  }
  getDropdownData(): any {
    // console.log('this.dropdownData', this.dropdownData)
    return this.dropdownData;
  }


  // setdata(data) {
  //   for (let x of tabArray) {
  //     let array1 = {}
  //     for (let y of x.value) {
  //       array1[optionArray[y].name] = (data[x.name] == undefined || data[x.name][optionArray[y].name] == undefined ? false : data[x.name][optionArray[y].name]);
  //     }
  //     permission[x.name] = array1;
  //   }
  //   permission['status'] = "updated";
  //   return true;
  // }
  getlocation() {
    return this.http.get<any>('https://ipapi.co/json/');
  }
  getPlaceHolder(selectedCountryISO) {
    return selectedCountryISO == 'pe' ? '912345678' : this.removeDialCode(this.phoneUtil.format(this.phoneUtil.getExampleNumber(selectedCountryISO), lpn.PhoneNumberFormat[this.PhoneNumberFormat.International]),selectedCountryISO);
  }

  removeDialCode(phoneNumber,selectedCountryISO) {
    const number = this.getParsedNumber(phoneNumber, selectedCountryISO);
    phoneNumber = this.phoneUtil.format(number, lpn.PhoneNumberFormat[this.PhoneNumberFormat.International]);
    if (phoneNumber.startsWith('+')) {
      phoneNumber = phoneNumber.substr(phoneNumber.indexOf(' ') + 1);
    }
    return phoneNumber;
  }

  getParsedNumber(phoneNumber, countryCode) {
    let number;
    try {
      number = this.phoneUtil.parse(phoneNumber, countryCode.toUpperCase());
    }
    catch (e) { }
    return number;
  }
}
