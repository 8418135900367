<div class="outer-header fixed-header outer">
    <div class="outer-logo">
      <img src="../../../assets/images/ic_logo2.svg" alt="logo">
    </div>

  </div>
  
  
<div class="terms-conditions">
    <div class="smallcontainer container">
        <div class="termsWrap">
            <div class="manage mt-3 sm-full-sr">
    <!-- START PAGE CONTENT-->
    <div class="right-side margin-auto">
        <div class="container business-section">
            
           <div class="row privacy" *ngIf="language1==1">
            <div class="col-sm-8">
                    <div class="title mb-4">
                        <p class="header-2"> {{'POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES'}}</p>
                    </div>
                    
               <h6 class="heading-5"> {{'I. INTRODUCCIÓN' }}</h6>
                     <p class="para-purple">BUKI PLANNER S.A. (en adelante, “Buki”), con RUC N° 20610927379 y domicilio para estos efectos en Av. Javier Prado Este N° 3580, San Borja, Lima; tiene un firme compromiso por el respeto y el cumplimiento de todas las disposiciones legales y reglamentarias que les son aplicables respecto al tratamiento de datos personales, específicamente a la Ley Nº 29733, Ley de Protección de Datos Personales, y su Reglamento, aprobado por el Decreto Supremo Nº 003-2013-JUS. </p>
           
            </div>

           <div class="col-sm-4">
                <img src="assets/images/ic_privacy_policy.png">
           </div>
           <div class="col-sm-12">
            <p class="para-purple">Asimismo, Buki entiende que los datos personales, al ser parte integrante de la privacidad de las personas; y al ser también fundamentales para nuestra actividad, deben ser tratados de tal forma que no sólo impliquen el cumplimiento del ordenamiento legal, sino que, además, se deben tomar medidas que generen un ambiente de confianza y seguridad en el público respecto a dicho tratamiento. En particular, Buki informa a todos sus usuarios acerca de su Política de Privacidad y Protección de Datos Personales, para que éstos determinen libre y voluntariamente si desean facilitar a Buki los datos personales que se les puedan requerir o que se puedan obtener sobre los mismos con fines comerciales.</p>
            <!-- <p class="header sub-heading date">Updated 2 May, 2023</p>  -->
   
           </div>
           </div>
           
           <div class="col-sm-12" *ngIf="language1==1">
               <h6 class="heading-5"> II. OBJETIVO</h6>
               <p class="para-p">La presente Política tiene por objeto hacer de conocimiento nuestro compromiso con la protección de los datos personales, así como los lineamientos bajo los cuales realizamos el tratamiento de los mismos en ejercicio de nuestras actividades, qué datos recaudamos, la finalidad para la cual lo hacemos, así como los procedimientos para que los titulares de datos puedan ejercer los derechos de acceso, rectificación, cancelación, revocación, información y oposición previstos en la Ley de Protección de Datos Personales.</p>
               <h6 class="heading-5">III. ÁMBITO DE APLICACIÓN</h6>
               <p class="para-p">La presente Política se aplica a toda actividad de tratamiento de datos personales realizada por parte de Buki, comprometiéndose a respetar los principios rectores establecidos en la Ley de Protección de Datos Personales. </p>
               <h6 class="heading-5">IV. DEFINICIONES</h6>
               <p class="para-p">Con la finalidad de que el usuario de la plataforma Buki pueda comprender los términos empleados en la presente Política, ponemos a su disposición las siguientes definiciones:</p>
               <p class="para-p"><b>Banco de datos personales:</b>Conjunto organizado de datos personales, automatizado o no, independientemente del soporte, sea este físico, magnético, digital, óptico u otros que se creen, cualquiera fuere la forma o modalidad de su creación, formación, almacenamiento, organización y acceso</p>
               <p class="para-p"><b>Datos Personales:</b>Toda información sobre una persona natural que la identifica o la hace identificable a través de medios que pueden ser razonablemente utilizados</p>
               <p class="para-p"><b>Datos sensibles:</b>Datos personales constituidos por los datos biométricos que por sí mismo pueden identificar al titular, datos referidos al origen racial y étnico; ingresos económicos, opiniones o convicciones políticas, religiosas, filosóficas o morales; afiliación sindical; e información relacionada a la salud o a la vida sexual.</p>
               <p class="para-p"><b>Titular de datos personales:</b>Persona natural a quien corresponden los datos personales.
               </p>
               <p class="para-p"><b>Titular del banco de datos personales:</b>Persona natural o persona jurídica de derecho privado o entidad pública que determina la finalidad y contenido del banco de datos personales, el tratamiento de estos y las medidas de seguridad.</p>
               <p class="para-p"><b>Transferencia de datos personales:</b>Toda transmisión, suministro o manifestación de datos personales, de carácter nacional o internacional, a una persona jurídica de derecho privado, a una entidad pública o a una persona natural distinta del titular de datos personales.</p>
               <p class="para-p"><b>Tratamiento de datos personales:</b>Cualquier operación o procedimiento técnico, automatizado o no, que permite la recopilación, registro, organización, almacenamiento, conservación, elaboración, modificación, extracción, consulta, utilización, bloqueo, supresión, comunicación por transferencia o por difusión o cualquier otra forma de procesamiento que facilite el acceso, correlación o interconexión de los datos personales.</p>
               <p class="para-p"><b>Derechos ARCO:</b>Derechos de acceso, rectificación, cancelación y oposición que ostenta toda persona natural titular de datos personales reconocidos en la Ley de Protección de Datos Personales y su Reglamento.</p>
               <p class="para-p"><b>Formato de Ejercicio de Derechos ARCO:</b>Es el pedido de acceso, rectificación, actualización, inclusión, cancelación, supresión u oposición, que formula el titular de datos personales respecto a su información ante el titular de un banco de datos personales.
               </p>
               <p class="para-p"><b>Consentimiento del titular:</b>es toda manifestación de voluntad libre, específica, informada e inequívoca por la que el titular del dato personal acepta y autoriza, ya sea mediante una declaración o una clara acción afirmativa, el tratamiento de datos personales que le conciernen.</p>
               
               <h6 class="heading-5">V. PRINCIPIOS RECTORES</h6>
               <p class="para-p">Buki está comprometido con el respeto de los principios rectores establecidos en la Ley de Protección de Datos Personales, los cuales detallamos a continuación:</p>
               <ul class="content-list small">
                <li><b>Principio de legalidad:</b> El tratamiento de los datos personales se hace conforme a lo establecido en la ley, estando prohibida la recopilación de los datos personales por medios fraudulentos, desleales o ilícitos.</li>
                <li><b>Principio de consentimiento:</b> Para el tratamiento de los datos personales se debe contar con el consentimiento de su titular, salvo que medie alguna de las excepciones previstas en la ley. Dicho consentimiento debe cumplir con los requisitos de ser libre, previo a su recopilación o tratamiento, expreso e inequívoco, e informado.</li>
                <li><b>Principio de finalidad:</b> Los datos personales deben ser recopilados para una finalidad determinada, explícita y lícita, y su tratamiento no debe extenderse a otra finalidad distinta a aquella para la cual fueron recopilados.</li>
                <li><b>Principio de proporcionalidad:</b> El tratamiento de datos personales debe ser adecuado, relevante y no excesivo a la finalidad para la que estos hubiesen sido recopilados.</li>
                <li><b>Principio de calidad:</b> Los datos personales que vayan a ser tratados deben ser veraces, exactos y, en la medida de lo posible, actualizados, necesarios, pertinentes y adecuados respecto de la finalidad para la que fueron recopilados.</li>
                <li><b>Principio de seguridad:</b> El titular del banco de datos personales y el encargado de su tratamiento deben adoptar las medidas técnicas, organizativas y legales necesarias para garantizar la seguridad de los datos personales.</li>
                <li><b>Principio de disposición de recurso:</b> El titular de los datos personales debe contar con las vías administrativas o jurisdiccionales necesarias para reclamar y hacer valer sus derechos, cuando estos sean vulnerados por el tratamiento de sus datos personales.</li>
                <li><b>Principio de nivel de protección adecuado:</b> Para el flujo transfronterizo de datos personales, se debe garantizar un nivel suficiente de protección para los datos personales que se vayan a tratar o, por lo menos, equiparable a lo previsto por la Ley de Protección de Datos Personales o por los estándares internacionales en la materia.</li>
               </ul>
               <h6 class="heading-5">VI. FINALIDAD DEL TRATAMIENTO DE LOS DATOS PERSONALES</h6>
               <p class="para-p">Buki realiza el tratamiento de datos personales de los clientes con la finalidad de cumplir con la legislación vigente, intermediar en la relación entre el Usuario y el Establecimiento Comercial o Freelancer que brinda los servicios de belleza en su plataforma, así como resguardar la seguridad de los datos de dichos usuarios.</p>
               <p class="para-p"><b>Banco de Datos de “Clientes”:</b></p>
               <p class="para-p">Buki cumple con el derecho de información y pone a conocimiento del usuario de la plataforma que sus datos personales serán recopilados, almacenados y tratados para los siguientes fines:</p>
               <ol>
                <li>Facilitar las relaciones entre los Usuarios y los Establecimientos Comerciales o Freelancers afiliados a la plataforma Buki para la prestación de los servicios de belleza.</li>
                <li>Elaboración de registros estadísticos o históricos de los Usuarios y de sus interacciones con los Establecimientos Comerciales y Freelancers.
                </li>
                <li>Desarrollar estudios internos sobre los intereses, comportamientos y demografía del Titular para comprender mejor sus necesidades e intereses y ofrecer mejores servicios o proveerles información relacionada</li>
               </ol>
               <p class="para-p">Para llevar a cabo las finalidades descritas, será necesario que se traten los siguientes datos: nombres, apellidos, número de DNI, número de RUC, número de Pasaporte, carné de extranjería, dirección del domicilio, teléfono, correo electrónico,  fecha de nacimiento, nacionalidad, sexo y datos bancarios. En caso de que el usuario no proporcione los datos personales antes señalados a Buki, este no podrá facilitar los servicios provistos por los Establecimientos Comerciales o Freelancers afiliados a la plataforma. </p>
               <p class="para-p">Los datos personales que se recopilen de los usuarios no se harán públicos, salvo que así lo requiera la Ley o las autoridades, ni se comercializarán a terceros de ningún modo.</p>
               <p class="para-p">El tratamiento de datos personales para cualquier otra finalidad lícita distinta a las antes mencionadas, será debidamente informada a los titulares de datos personales, requiriéndoles una autorización específica en cumplimiento del Principio de consentimiento. </p>
               <p class="para-p">Para el caso en particular, las finalidades adicionales para las cuales podríamos requerir el consentimiento señalado en el párrafo anterior serían las siguientes: envío de publicidad, actividades de marketing, realizar encuestas de satisfacción, ofrecer productos y servicios con fines publicitarios. De este modo, se le solicitará al cliente su consentimiento expreso para que Buki pueda hacer uso de sus datos a fin de remitirle información y publicidad, entendiéndose por expreso cualquier aceptación expresada mediante un “check”, “click”, “aspa”, “apertura de enlace”, entre otros, mediante los cuales se pueda comunicar la conformidad.</p>
               <h6 class="heading-5">VII. CONSENTIMIENTO</h6>
               <p class="para-p">Buki requerirá del consentimiento libre, previo, expreso, inequívoco e informado del titular de los datos personales para el tratamiento de estos, salvo en los casos de excepción expresamente establecidos por Ley.</p>
               <p class="para-p">Buki no requerirá consentimiento para tratar los datos personales obtenidos de fuentes accesibles al público, gratuitas o no, para el uso por el cual fueron hechos públicos; cuando sean necesarios para la preparación, celebración y ejecución de una relación contractual en la que el titular de datos personal sea parte; cuando se hubiese aplicado un procedimiento de anonimización o disociación; entre otras excepciones establecidas en el artículo 14° de la Ley de Protección de Datos Personales. Asimismo, Buki podrá tratar sus datos personales obtenidos de fuentes no públicas, siempre que dichas fuentes cuenten con su consentimiento para tratar y transferir dichos datos personales.</p>
               <p class="para-p">Buki informa que los bancos de datos cuya titularidad ostenta, se encuentran inscritos en el Registro de Protección de Datos de la Autoridad de Protección de Datos Personales, conforme se detalla a continuación:</p>
               <h6 class="heading-5">BUKI PELUPLANNER S.A.C</h6>
               <p class="para-p">Solicitud de registro N° 73515: Banco de datos denominado “Usuarios Plataforma Virtual BUKI” ubicado en Av. Javier Prado Este N° 3580, distrito de San Borja, Provincia y Departamento de Lima.   </p>
               <h6 class="heading-5">X. DERECHOS DE LOS TITULARES</h6>
               <p class="para-p">De acuerdo con la Ley, los titulares de datos personales tienen los siguientes derechos:</p>
               <p class="para-p"><b>1. Derecho de Acceso e información:</b> El titular de datos personales tiene derecho a obtener la información sobre sí mismo que sea objeto de tratamiento en los bancos de datos de titularidad de Buki. El derecho de información otorga al titular el derecho de conocer la finalidad para la cual sus datos serán tratados, la existencia del banco de datos en que serán almacenados, la identidad y domicilio del titular del banco de datos y de los encargados del tratamiento, si se produjera la transferencia de datos personales y a quienes, el tiempo de conservación, entre otros.</p>
               <p class="para-p"><b>2. Derecho de rectificación, actualización e inclusión:</b> El titular de datos personales tiene derecho a la actualización, inclusión y rectificación de sus datos personales materia de tratamiento por parte de Buki cuando estos sean parcial o totalmente inexactos, incompletos o cuando se hubiere advertido omisión, error o falsedad.</p>
               <p class="para-p"><b>3. Derecho de Cancelación o Supresión</b> El titular de datos personales podrá solicitar la cancelación o supresión de sus datos personales no relacionados o necesarios para lograr las finalidades informadas. </p>
               <p class="para-p"><b>4. Derecho de Oposición:</b> El titular de datos personales puede oponerse al tratamiento de sus datos personales en cualquier momento. La oposición procederá en la medida que el tratamiento no tenga justificación contractual o legal.</p>
               <p class="para-p"><b>5. Derecho de revocación:</b> El titular de datos personales puede retirar en cualquier momento el consentimiento otorgado de manera previa. La revocación no alcanzará a los usos y/o tratamientos que puedan ejecutarse en los escenarios autorizados por la regulación.</p>
               <h6 class="heading-5">XI. PROCEDIMIENTO PARA EL EJERCICIO DE LOS DERECHOS DEL TITULAR DE LOS DATOS PERSONALES</h6>
               <p class="para-p">Los titulares de datos podrán revocar su consentimiento o ejercer sus derechos conforme a Ley, dirigiéndose al correo: <a>soporte@bukiglobal.com.</a> </p>
               <p class="para-p">Buki ha implementado un procedimiento interno para atender de forma oportuna la solicitud de ejercicio de los derechos ARCO por parte de sus titulares. </p>
               <p class="para-p">En caso de que el titular de los datos personales requiera ejercer sus derechos mediante un representante, éste deberá enviar una carta poder legalizada por un notario público que lo faculte como tal y su documento de identidad.</p>
               <h6 class="heading-5">XII. PLAZO DEL TRATAMIENTO DE DATOS PERSONALES</h6>
               <p class="para-p">Los datos personales tratados por Buki serán tratados durante el tiempo que dure la afiliación o registro del usuario en la plataforma digital Buki y, posteriormente a la misma, se conservarán para fines promocionales hasta que se revoque el consentimiento expreso otorgado para ello. Sin perjuicio de ello, el titular de los datos personales podrá ejercer en cualquier momento los derechos ARCO mencionados en el numeral X de esta Política.</p>
               <h6 class="heading-5">XIII. SEGURIDAD DE DATOS PERSONALES</h6>
               <p class="para-p">En cumplimiento de la Normativa de Protección de Datos Personales vigente, Buki ha adoptado las medidas jurídicas, organizativas y técnicas apropiadas para garantizar la seguridad de los datos personales, evitando su alteración, mal uso, pérdida, tratamiento indebido o acceso no autorizado; cumpliendo con lo estipulado en la Directiva de Seguridad emitida por la Autoridad Nacional de Protección de Datos Personales. A pesar de ello, los usuarios deben ser conscientes de que las medidas de seguridad en internet no son inexpugnables.</p>
               <p class="para-p">Cabe señalar que Buki ha designado un responsable, quien coordinará la aplicación de las medidas de seguridad pertinentes, respecto del banco de datos denominado “Usuarios Plataforma Virtual Buki”.</p>
               <p class="para-p">Para este propósito, Buki pone a disposición todos los recursos humanos y tecnológicos necesarios, aplicándolos en proporción a la naturaleza de los datos almacenados y los riesgos a los que se encuentran expuestos.</p>
               <p class="para-p">Buki sólo realizará tratamientos sobre datos personales que estén almacenados de forma física o electrónica cumpliendo las condiciones de seguridad exigidas por la Legislación de Protección de Datos Personales vigente.</p>
               <p class="para-p">Buki declara haber implementado los controles adecuados de un sistema de gestión de seguridad de la información, teniendo como lineamiento los requisitos señalados en la Ley de Protección de Datos Personales vigente.</p>
               <p class="para-p">Buki declara contar con una estructura organizacional con roles y responsabilidades de acuerdo con la proporcionalidad de los datos a proteger.</p>
               <h6 class="heading-5">XIV. TRANSFERENCIA DE DATOS PERSONALES</h6>
               <p class="para-p">Buki podrá transferir datos personales a entidades públicas legalmente facultadas dentro del ámbito de sus competencias, en cumplimiento de la normativa vigente, o por requerimientos de éstas o cuando medie alguna de las excepciones previstas en la Ley de Protección de Datos Personales. </p>
               <p class="para-p">Al respecto, Buki garantiza que, en el marco de la comunicación de datos, se respetarán en todo momento los principios rectores de la protección de datos personales.</p>
               <h6 class="heading-5">XV. TRATAMIENTO DE DATOS DE MENORES DE EDAD</h6>
               <p class="para-p">Buki comprende la importancia de proteger la privacidad de los menores de edad; por tal motivo, no llevará a cabo el tratamiento de datos personales relativos a menores de edad. En el supuesto de que se tenga conocimiento que los datos personales recopilados corresponden a un menor de edad, Buki adoptará las medidas oportunas para su eliminación.</p>
               <h6 class="heading-5">XVII. MODIFICACIONES
            </h6>
            <p class="para-p">Buki se reserva el derecho de modificar o actualizar la información contenida en la presente Política de Privacidad y Protección de Datos Personales; en cuyo caso, el texto vigente de la misma será publicado a través de su plataforma digital Buki. En tal sentido, se recomienda al usuario verificar de forma regular la información contenida en la presente Política. </p>
           </div>
           <div class="row privacy" *ngIf="language1==2">
            <div class="col-sm-8">
                <div class="title mb-4">
                    <p class="header-2"> {{'PRIVACY POLICY AND PERSONAL DATA PROTECTION'}}</p>
                </div>
               
                <h6 class="heading-5"> {{'I. INTRODUCTION' }}</h6>
                <p class="para-purple">BUKI PLANNER S.A. (hereinafter, “Buki”), with RUC No. 20610927379 and address for these purposes at Av. Javier Prado Este No. 3580, San Borja, Lima; is firmly committed to respecting and complying with all legal and regulatory provisions applicable to the processing of personal data, specifically Law No. 29733, the Personal Data Protection Law, and its Regulation, approved by Supreme Decree No. 003-2013-JUS.</p>
            </div>
        
            <div class="col-sm-4">
                <img src="assets/images/ic_privacy_policy.png">
            </div>
            <div class="col-sm-12">
                <p class="para-purple">Furthermore, Buki understands that personal data, as an integral part of people's privacy and also fundamental to our activity, should be treated in such a way that not only complies with the legal order, but also takes measures to generate an atmosphere of trust and security in the public regarding such processing. In particular, Buki informs all its users about its Privacy and Personal Data Protection Policy, so that they may freely and voluntarily decide whether they wish to provide Buki with the personal data that may be required or that may be obtained about them for commercial purposes.</p>
            </div>
        </div>
        
        <div class="col-sm-12" *ngIf="language1==2">
            <h6 class="heading-5"> II. OBJECTIVE</h6>
            <p class="para-p">This Policy aims to make known our commitment to the protection of personal data, as well as the guidelines under which we process them in the exercise of our activities, what data we collect, the purpose for which we do it, and the procedures for data subjects to exercise their rights of access, rectification, cancellation, revocation, information, and opposition provided for in the Personal Data Protection Law.</p>
            <h6 class="heading-5">III. SCOPE OF APPLICATION</h6>
            <p class="para-p">This Policy applies to all personal data processing activities carried out by Buki, committing to respect the guiding principles established in the Personal Data Protection Law.</p>
            <h6 class="heading-5">IV. DEFINITIONS</h6>
            <p class="para-p">To help the user of the Buki platform understand the terms used in this Policy, we provide the following definitions:</p>
            <p class="para-p"><b>Personal data bank:</b> Organized set of personal data, automated or not, regardless of the medium, be it physical, magnetic, digital, optical, or other, created in any form or modality of its creation, formation, storage, organization, and access.</p>
            <p class="para-p"><b>Personal Data:</b> Any information about a natural person that identifies or makes them identifiable through means that can be reasonably used.</p>
            <p class="para-p"><b>Sensitive data:</b> Personal data consisting of biometric data that by itself can identify the holder, data related to racial and ethnic origin; economic income, political, religious, philosophical, or moral beliefs or convictions; trade union membership; and information related to health or sexual life.</p>
            <p class="para-p"><b>Data subject:</b> Natural person to whom the personal data belongs.</p>
            <p class="para-p"><b>Owner of the personal data bank:</b> Natural or legal person of private law or public entity that determines the purpose and content of the personal data bank, the processing of these and the security measures.</p>
            <p class="para-p"><b>Transfer of personal data:</b> Any transmission, provision or disclosure of personal data, nationally or internationally, to a private law legal entity, a public entity, or a natural person other than the data subject.</p>
            <p class="para-p"><b>Processing of personal data:</b> Any operation or technical procedure, automated or not, that allows the collection, registration, organization, storage, preservation, processing, modification, extraction, consultation, use, blocking, deletion, communication by transfer or dissemination or any other form of processing that facilitates the access, correlation or interconnection of the personal data.</p>
            <p class="para-p"><b>ARCO Rights:</b> Rights of access, rectification, cancellation, and opposition that every natural person data subject has recognized in the Personal Data Protection Law and its Regulation.</p>
            <p class="para-p"><b>Form for Exercising ARCO Rights:</b> The request for access, rectification, update, inclusion, cancellation, deletion or opposition, that the data subject makes regarding their information before the owner of a personal data bank.</p>
            <p class="para-p"><b>Consent of the data subject:</b> Any expression of will free, specific, informed, and unequivocal by which the data subject accepts and authorizes, either by a declaration or a clear affirmative action, the processing of personal data concerning them.</p>
           
            <h6 class="heading-5">V. GUIDING PRINCIPLES</h6>
            <p class="para-p">Buki is committed to respecting the guiding principles established in the Personal Data Protection Law, which we detail below:</p>
            <ul class="content-list small">
                <li><b>Principle of legality:</b> The processing of personal data is conducted in accordance with the law, and the collection of personal data by fraudulent, unfair, or illicit means is prohibited.</li>
                <li><b>Principle of consent:</b> The processing of personal data must have the consent of the data subject, except in cases provided for by law. Such consent must be free, prior to its collection or processing, express and unequivocal, and informed.</li>
                <li><b>Principle of purpose:</b> Personal data must be collected for a specific, explicit, and lawful purpose, and their processing must not extend to any purpose other than that for which they were collected.</li>
                <li><b>Principle of proportionality:</b> The processing of personal data must be adequate, relevant, and not excessive for the purpose for which they were collected.</li>
                <li><b>Principle of quality:</b> The personal data to be processed must be truthful, accurate, and, as much as possible, updated, necessary, relevant, and adequate regarding the purpose for which they were collected.</li>
                <li><b>Principle of security:</b> The owner of the personal data bank and the person in charge of its processing must adopt the necessary technical, organizational, and legal measures to ensure the security of the personal data.</li>
                <li><b>Principle of resource availability:</b> The data subject must have the necessary administrative or jurisdictional means to claim and enforce their rights when they are violated by the processing of their personal data.</li>
                <li><b>Principle of adequate level of protection:</b> For the cross-border flow of personal data, a sufficient level of protection for the personal data to be processed must be ensured, or at least comparable to that provided by the Personal Data Protection Law or international standards on the matter.</li>
            </ul>
            <h6 class="heading-5">VI. PURPOSE OF THE PROCESSING OF PERSONAL DATA</h6>
            <p class="para-p">Buki processes the personal data of customers to comply with current legislation, mediate the relationship between the User and the Commercial Establishment or Freelancer providing beauty services on its platform, and safeguard the security of such users' data.</p>
            <p class="para-p"><b>Database of “Clients”:</b></p>
            <p class="para-p">Buki complies with the right to information and informs the user of the platform that their personal data will be collected, stored, and processed for the following purposes:</p>
            <ol>
                <li>Facilitate relationships between Users and Commercial Establishments or Freelancers affiliated with the Buki platform for the provision of beauty services.</li>
                <li>Development of statistical or historical records of Users and their interactions with Commercial Establishments and Freelancers.</li>
                <li>Conduct internal studies on the interests, behaviors, and demographics of the Data Subject to better understand their needs and interests and offer better services or provide related information.</li>
            </ol>
            <p class="para-p">To carry out the purposes described, it will be necessary to process the following data: names, surnames, DNI number, RUC number, Passport number, foreigner card, home address, telephone, email, date of birth, nationality, gender, and banking data. If the user does not provide Buki with the aforementioned personal data, Buki will not be able to facilitate the services provided by the Commercial Establishments or Freelancers affiliated with the platform.</p>
            <p class="para-p">The personal data collected from users will not be made public, except as required by Law or authorities, nor will they be marketed to third parties in any way.</p>
            <p class="para-p">The processing of personal data for any other lawful purpose different from those mentioned above will be duly informed to the data subjects, requiring a specific authorization in compliance with the Principle of consent.</p>
            <p class="para-p">For this particular case, the additional purposes for which we could require the consent mentioned in the previous paragraph would be the following: sending advertising, marketing activities, conducting satisfaction surveys, offering products and services for advertising purposes. In this way, the client's express consent will be requested so that Buki can use their data to send information and advertising, understanding by express any acceptance expressed through a "check", "click", "tick", "link opening", among others, through which agreement can be communicated.</p>
            <h6 class="heading-5">VII. CONSENT</h6>
            <p class="para-p">Buki will require the free, prior, express, unequivocal, and informed consent of the data subject for the processing of these data, except in cases of exception expressly established by Law.</p>
            <p class="para-p">Buki will not require consent to process personal data obtained from publicly accessible sources, free or not, for the use for which they were made public; when they are necessary for the preparation, conclusion, and execution of a contractual relationship in which the data subject is a party; when an anonymization or dissociation procedure has been applied; among other exceptions established in article 14° of the Personal Data Protection Law. Additionally, Buki may process your personal data obtained from non-public sources, provided that such sources have your consent to process and transfer such personal data.</p>
            <p class="para-p">Buki informs that the databases of which it holds ownership are registered in the Data Protection Registry of the Personal Data Protection Authority, as detailed below:</p>
            <h6 class="heading-5">BUKI PELUPLANNER S.A.C</h6>
            <p class="para-p">Registration request No. 73515: Database called “Virtual Platform BUKI Users” located at Av. Javier Prado Este No. 3580, district of San Borja, Province and Department of Lima.</p>
            <h6 class="heading-5">X. RIGHTS OF THE HOLDERS</h6>
            <p class="para-p">In accordance with the Law, the holders of personal data have the following rights:</p>
            <p class="para-p"><b>1. Right of Access and information:</b> The data subject has the right to obtain information about themselves that is subject to processing in the databases owned by Buki. The right to information grants the holder the right to know the purpose for which their data will be processed, the existence of the database in which they will be stored, the identity and address of the owner of the database and those in charge of the processing, if there is a transfer of personal data and to whom, the duration of conservation, among others.</p>
            <p class="para-p"><b>2. Right of rectification, updating, and inclusion:</b> The data subject has the right to update, include, and rectify their personal data subject to processing by Buki when these are partially or totally inaccurate, incomplete, or when an omission, error, or falsehood has been noticed.</p>
            <p class="para-p"><b>3. Right of Cancellation or Deletion:</b> The data subject may request the cancellation or deletion of their personal data not related or necessary to achieve the informed purposes.</p>
            <p class="para-p"><b>4. Right of Opposition:</b> The data subject can oppose the processing of their personal data at any time. Opposition will proceed to the extent that the processing does not have contractual or legal justification.</p>
            <p class="para-p"><b>5. Right of revocation:</b> The data subject can withdraw at any time the consent previously granted. Revocation will not affect the uses and/or treatments that can be carried out in the scenarios authorized by the regulation.</p>
            <h6 class="heading-5">XI. PROCEDURE FOR THE EXERCISE OF THE RIGHTS OF THE DATA SUBJECT</h6>
            <p class="para-p">The data subjects may revoke their consent or exercise their rights according to the Law, by addressing the email: <a>soporte@bukiglobal.com.</a> </p>
            <p class="para-p">Buki has implemented an internal procedure to timely address the request for exercise of ARCO rights by its holders.</p>
            <p class="para-p">In case the data subject requires to exercise their rights through a representative, they must send a power of attorney legalized by a public notary that empowers them as such and their identity document.</p>
            <h6 class="heading-5">XII. DURATION OF THE PROCESSING OF PERSONAL DATA</h6>
            <p class="para-p">The personal data processed by Buki will be processed for the duration of the affiliation or registration of the user on the Buki digital platform and, subsequently, will be kept for promotional purposes until the express consent granted for it is revoked. Notwithstanding this, the data subject may exercise at any time the ARCO rights mentioned in numeral X of this Policy.</p>
            <h6 class="heading-5">XIII. SECURITY OF PERSONAL DATA</h6>
            <p class="para-p">In compliance with the current Personal Data Protection Regulations, Buki has adopted the appropriate legal, organizational, and technical measures to ensure the security of personal data, avoiding its alteration, misuse, loss, improper processing, or unauthorized access; complying with the Security Directive issued by the National Authority for Personal Data Protection. Despite this, users should be aware that security measures on the internet are not impregnable.</p>
            <p class="para-p">It should be noted that Buki has designated a person responsible, who will coordinate the application of the relevant security measures, regarding the database called “Virtual Platform Buki Users”.</p>
            <p class="para-p">For this purpose, Buki makes available all necessary human and technological resources, applying them in proportion to the nature of the data stored and the risks to which they are exposed.</p>
            <p class="para-p">Buki will only carry out treatments on personal data that are stored physically or electronically, complying with the security conditions required by the current Personal Data Protection Legislation.</p>
            <p class="para-p">Buki declares to have implemented the appropriate controls of an information security management system, having as a guideline the requirements indicated in the current Personal Data Protection Law.</p>
            <p class="para-p">Buki declares to have an organizational structure with roles and responsibilities according to the proportionality of the data to protect.</p>
            <h6 class="heading-5">XIV. TRANSFER OF PERSONAL DATA</h6>
            <p class="para-p">Buki may transfer personal data to legally empowered public entities within the scope of their competencies, in compliance with current regulations, or by their requirements or when any of the exceptions provided for in the Personal Data Protection Law apply.</p>
            <p class="para-p">In this regard, Buki guarantees that, in the context of data communication, the guiding principles of personal data protection will be respected at all times.</p>
            <h6 class="heading-5">XV. PROCESSING OF DATA OF MINORS</h6>
            <p class="para-p">Buki understands the importance of protecting the privacy of minors; therefore, it will not carry out the processing of personal data relating to minors. In the event that it becomes known that the personal data collected correspond to a minor, Buki will take appropriate measures for their deletion.</p>
            <h6 class="heading-5">XVII. MODIFICATIONS</h6>
            <p class="para-p">Buki reserves the right to modify or update the information contained in this Privacy Policy and Personal Data Protection; in which case, the current text will be published through its digital platform Buki. In this regard, it is recommended that the user regularly check the information contained in this Policy.</p>
        </div>
       </div>
    </div>    
  
</div>   </div>
    </div>
</div>