<div class="manage content-wrapper termsWrap">
    <!-- START PAGE CONTENT-->
    <div class="right-side margin-auto">
        <div class="container business-section">

<!--        
            <div class="title mb-4">
                <p class="header-2">About Buki</p>
            </div> -->
        <div *ngIf="aboutList?.about_us_sections?.length > 0">    
            <div class="banner_img">
                <img [src]="aboutList?.image ? (BaseUrl + aboutList?.image) :''" alt="">
            </div>

            <div class="title mb-0 mt-5" *ngFor="let data of aboutList?.about_us_sections">
                <p class="header sub-heading">{{langugaeCheck(data)}}</p>
                <p class="para">
                    {{langugaeCheck2(data)}}
                </p>
            </div>  
        </div>      

   
       </div>
    </div>    
  
</div>
