import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { AdminService } from "./admin.service";
import { DataSharingService } from "./data.share.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private localStorage: DataSharingService) {}
  canActivate() {
    let token:any = this.localStorage.getItem('accessInfo') ? JSON.parse(this.localStorage.getItem('accessInfo') || '') : '';
    // console.log(token)
    if (token && token.accessToken) {
      // console.log(token);
      return true;
    }else{
      this.router.navigate([""]);
      return false;
    }

  }
}
