import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.css']
})
export class PaymentErrorComponent implements OnInit {
  languageCheck:any
  constructor() { }

  ngOnInit(): void {
   this.languageCheck = localStorage.getItem('language')
  }

}
