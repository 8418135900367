import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-terms',
  templateUrl: './web-terms.component.html',
  styleUrls: ['./web-terms.component.css']
})
export class WebTermsComponent implements OnInit {
  language1:any=1;
  constructor() { 
    
    let language:any =localStorage.getItem('language')
    this.language1=language== 1?1:2 }

  ngOnInit(): void {
  }

}
