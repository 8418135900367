import { Component, OnInit, Renderer2 } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { DataSharingService } from "src/app/services/data.share.service";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { environment } from '../../../environments/environment';
import { Subscription } from "rxjs";
import { MessagingService } from "src/app/services/messaging.service";

@Component({
  selector: 'app-internal-header',
  templateUrl: './internal-header.component.html',
  styleUrls: ['./internal-header.component.css']
})
export class InternalHeaderComponent implements OnInit {
  urlCheck: any
  SeletedData: any = 0;
  userData: any;
  BaseUrl: any;
  toggle: boolean = false;
  type: any;
  showButton = false;
  showManageBar = false;
  currentTab = 0;
  notificationList;
  today= new Date();
  selectedIndex=0;
  subscription:Subscription;
  notificationDetails:any='';
  language:any
  constructor(private router: Router,
    private dataSharingService: DataSharingService,
    private api: AdminService,
    private fcmService: MessagingService,
    private renderer: Renderer2
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // console.log(val.url)
        if ((val.url || '').includes('?')) {
          //  console.log('treu', val.url.split('?', 1))
          let data = val.url.split('?', 1)
          this.urlCheck = data[0]
          //  console.log('treu', this.urlCheck)
        } else {
          this.urlCheck = val.url
          //  console.log('ssd', this.urlCheck)
        }
      }
    });
    this.fcmService.currentMessage.subscribe((value) => {
      // console.log('notification=======>' +value)
      if (value) {
          this.getNotifications();
      }
    });
    this.subscription= this.dataSharingService.isHelpnotification.subscribe(res=>{
      if(res)
      {
        this.getNotifications();
      }
    })

    // document.addEventListener(
    //   "visibilitychange"
    //   , () => {
        
    //     if (document.hidden) {
    //     } else {
    //       this.userData = this.dataSharingService.getItem('userInfo') ? JSON.parse(this.dataSharingService.getItem('userInfo') || '') : ''
    //       if(this.userData?.userDetails?.webFinished == 1){
    //         this.dataSharingService.helpPage.next(true)
    //        this.getNotifications();
    //       }
    //     }
    //   }
    // );

  }

  ngOnInit(): void {
    
    let language =localStorage.getItem('language')
    this.language=language
    this.BaseUrl = environment.BaseUrl + 'users/';
    this.userData = this.dataSharingService.getItem('userInfo') ? JSON.parse(this.dataSharingService.getItem('userInfo') || '') : ''
    this.dataSharingService.userAvatar.subscribe((res) => {
      if (res) {
        // console.log('rs', res)
        this.userData = this.dataSharingService.getItem('userInfo') ? JSON.parse(this.dataSharingService.getItem('userInfo') || '') : ''
      }
    })
    if (localStorage.getItem('language')) {
      this.type = localStorage.getItem('language')
    } else {
      localStorage.setItem('language', '1')
    }
    this.dataSharingService.showManageSideBar.subscribe(res => {
      this.showManageBar = res;
    })
    this.getNotifications();
  }

  ngAfterContentInit() {
    if (this.urlCheck == '/account-settings') {
      this.showManageBar = true;
    }
  }
  logout() {
    this.api.logOut()
  }



  sideToggle() {
    let body = document.getElementsByTagName('body')[0];
    if (!this.toggle) {
      body.classList.add("sidebar-mini");
      this.toggle = true;
    } else {
      body.classList.remove("sidebar-mini");
      this.toggle = false;
    }
  }


  showManageSideBar() {
    this.dataSharingService.showManageSideBar.next(true);
  }
  hideSideBar() {
    this.dataSharingService.showSideBar.next(false);
    this.dataSharingService.showManageSideBar.next(false);
    this.showManageBar = true;
  }
  getNotifications() {
    let data: any = {
      limit:5
    }
    if (this.currentTab !== 0) {
      data.ofType = this.currentTab
    }
    this.api.getData(apiUrl._notifications, data).subscribe(res => {
      this.notificationList = res.data;
      this.notificationList.data.rows.forEach(x=>{
        x.createdAt=new Date(x.createdAt);
      })
    })
  }
  handleChangeTab(type) {
    this.currentTab = type;
    this.getNotifications();
  }
  markAllRead() {
    let data = {
      status: "1"
    }
    this.api.putData(apiUrl._markAllRead, data).subscribe(res => {
      this.getNotifications();
      this.dataSharingService.isHelpnotification.next(true);
    }, (error) => {
      console.log(error);
    })
  }
  navigateNotification(notification)
  {
    // console.log('notification', notification)
    if(notification.status==0)
    {
      let data={
        status: "1",
        id: notification.id
      }
      this.api.putData(apiUrl._markRead,data).subscribe(res=>{
        this.getNotifications();
        this.dataSharingService.isHelpnotification.next(true);
      },(error)=>{console.log(error)})
    }
    if(notification.ofType==1 || notification.ofType==4)
    {
      this.dataSharingService.showSideBar.next(true);
      this.dataSharingService.showManageSideBar.next(false);
      this.router.navigate(['/appointments/appointments-details/'+notification.objectId])
    }
    if(notification.ofType==0 )
    {
      this.dataSharingService.showSideBar.next(true);
      this.dataSharingService.showManageSideBar.next(false);
      this.router.navigate(['/help'])
    }

    if((notification.status==0 || notification.status==1) && (notification.ofType==0 || notification.ofType == 5)){
      this.notificationDetails =notification
      this.selectedIndex = 1
      this.renderer.addClass(document.body, 'modal-opn')
    }
    if(notification.ofType==2 ){
      this.router.navigate(['/reviews'])
    }
    

  }
  ngOnDestroy(): void {
    this.selectedIndex = 0

    this.renderer.removeClass(document.body, 'modal-opn');
  }
  close(){
    this.selectedIndex = 0
    this.renderer.removeClass(document.body, 'modal-opn');
  }

  leaveManage(){
    this.dataSharingService.showManageSideBar.next(false);
    this.dataSharingService.showSideBar.next(true);
  
  }
  
}


