import { Component, OnInit, Input, Output, Inject, EventEmitter, PLATFORM_ID } from '@angular/core';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { apiUrl } from "../../global/global";
import { AdminService } from "../../services/admin.service";
import { ActivatedRoute } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { TransferState, makeStateKey } from '@angular/platform-browser';
const DATA_KEY = makeStateKey<any>('data');

@Component({
  selector: 'app-mobile-faq',
  templateUrl: './mobile-faq.component.html',
  styleUrls: ['./mobile-faq.component.css']
})
export class MobileFaqComponent implements OnInit {
  text: any = "Loading...";
  faqList:any=[];
  language:any='';
  showThis:any='';
  hide = 0;
  isServer:any = 1;
  isBrowser:any='';
  language1:any;
  constructor(private api: AdminService,  private route: ActivatedRoute,  private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,) { }

  ngOnInit(): void {
    let language:any =localStorage.getItem('language')
    this.language1=language== 1?1:2
    // this.isBrowser = isPlatformBrowser('2');
    // console.log(' this.isBrowser',  this.isBrowser)
    this.language = this.route.snapshot.paramMap.get('id');
    if (isPlatformServer(this.platformId)) {
      this.getFaq();    
    } else {
      this.faqList = this.transferState.get(DATA_KEY, null);
    } 
  }
  getFaq() {
    let params = new HttpParams();
    this.api.getDataWithoutLoader(apiUrl._getFaq, params).subscribe((res) => {
      if (res) {
      
        this.faqList=res.data;
        this.transferState.set(DATA_KEY, res.data);
        this.text = this.language1==2 ? 'No records found' : this.language1== 1 ? 'No se encontraron registros' :'Nenhum registro foi encontrado'
      }
    })
    // console.log('ss', this.showThis,this.faqList)
  }

  langugaeCheck(item){
    if(this.language == 1){
      return (item.question_spanish != null)?item.question_spanish:(item.question_english!=null)?item.question_english:item.question_portugese

    }else if(this.language == 2){
      return (item.question_english != null)?item.question_english:(item.question_spanish!=null)?item.question_spanish:item.question_portugese

    }
    // else if(this.language == 3){
    // return (item.question_portugese!=null)?item.question_portugese:(item.question_spanish!=null)?item.question_spanish:item.question_english
    // }
  };
  langugaeCheck2(item){
    if(this.language == 1){
      return (item.answer_spanish != null)?item.answer_spanish:(item.answer_english!=null)?item.answer_english:item.answer_portugese

    }else if(this.language == 2){
      return (item.answer_english != null)?item.answer_english:(item.answer_spanish!=null)?item.answer_spanish:item.answer_portugese

    }
    // else if(this.language == 3){
    // return (item.answer_portugese!=null)?item.answer_portugese:(item.answer_spanish!=null)?item.answer_spanish:item.answer_english
    // }
  };
}
