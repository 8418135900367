import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-internal",
  templateUrl: "./internal.component.html",
  styleUrls: ["./internal.component.css"],
})
export class InternalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    console.log('enter')
  }

  
}
