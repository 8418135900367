<div class="formView">
<div class="form" *ngIf="!hidePayment">
    <div class="container">
      <div id="myPaymentForm">
        <div class="kr-smart-form" kr-card-form-expanded></div>
      </div>
      <div data-test="payment-message">{{ message }}</div>
    </div>
  </div>
</div>  
  