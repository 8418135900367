export const apiUrl = {
	
	_userRegistionCheck :"api/v1/serviceProvider/register",
	_userVerify :"api/v1/serviceProvider/verify-otp",
	_userResendotp :"api/v1/serviceProvider/resendSignUpOtp",
	_userResendotpmobile :"api/v1/serviceProvider/resendSignUpOtpMobile",
	
	_emailLogin: "api/v1/serviceProvider/login",
	_forgotPassword: "api/v1/serviceProvider/forgotPassword",
	_userVerifyPhone :"api/v1/serviceProvider/userMobileVerifyOtp",
	_userDetail :"api/v1/serviceProvider/getUserDetails",
	_secondaryPhone :"api/v1/serviceProvider/secondaryPhoneVerification",
	_register: 'api/v1/serviceProvider/completeProfile',
	_userResendmobileotp :"api/v1/serviceProvider/userMobileVerifyOtp",
	_secondaryEmail :"api/v1/serviceProvider/secondaryEmailVerification",
	_forgotOtp:'api/v1/serviceProvider/verifyForgetPasswordOtp',
	_forgotresendOtp:'api/v1/serviceProvider/resendForgotPasswordOtp',
	_resetPassword: "api/v1/serviceProvider/resetPassword",
	_team:'api/v1/serviceProvider/teamSize',
	_businessProfile :"api/v1/serviceProviderBusiness/businessProfile",
	_businessProfileupdate :"api/v1/serviceProviderBusiness/updateBusinessProfile",
	_workExperience :"api/v1/serviceProviderBusiness/workExperience",
	_accountReview :"api/v1/serviceProvider/sendForApproval",
	_finishedReview :"api/v1/serviceProvider/webFinished",

	_updateworkExperience :"api/v1/serviceProviderBusiness/updateWorkExperience",
	_travelPreference :"api/v1/serviceProviderBusiness/travelPreference",
	_travelToggle :"api/v1/serviceProviderBusiness/homeServiceProfile",
	_updateTravelPreference :"api/v1/serviceProviderBusiness/updateTravelPreference",
	
	_upload:"api/v1/common/getServiceProviderSignedURL",
	_uploadwithoutauth:"api/v1/common/getSalonSignedURL",
	_addPortfolio :"api/v1/serviceProviderBusiness/workImages",
	_updatePortfolio :"api/v1/serviceProviderBusiness/updateWorkImages",

	_addteamMmber :"api/v1/salon/addTeamMember",
	_getTeam :"api/v1/salon/getList",
	_getTeamdetail :"api/v1/salon/details",
	_updateTeamMember :"api/v1/salon/updateTeamMember",
	_deleteTeamMember :"api/v1/salon/deleteTeamMember",


	_languageTags:"api/v1/service/tags",
	_getCategoarylist:"api/v1/serviceProvider/categories",
	_addService:"api/v1/service",
	_getCategoary:"api/v1/service",
	_getsubCategoary:"api/v1/service/subCategories",
	_subCategoaryinner:"api/v1/service/list",
	_deleteService :"api/v1/service",
	_updateService :"api/v1/service",
	_updateSeqance :"api/v1/service/categorySequence",
	_updateSeqancesub :"api/v1/service/subcategorySequence",
	_getserviceDetail :"api/v1/service/details",
	_getLanguage:"api/v1/serviceProvider/getLanguageType",

	_getAdmindetails:"api/v1/salon/detailUsingEmail",
	_addAdmindetails:"api/v1/salon/completeTeamMember",
	_getAppointments:"api/v1/serviceProvider/appointments",
	_getClientdropdown:"api/v1/serviceProvider/clientList",
	_getAppointmentDetails:"api/v1/serviceProvider/appointments/details",
	_declineAppointment:"api/v1/serviceProvider/appointments/acceptOrDecline",
	_cancelAppointment:"api/v1/serviceProvider/appointments/cancel",
	_getteamAppointment:"api/v1/serviceProvider/appointments/teamMembers",
	getRescheduletime:"api/v1/serviceProvider/appointments/selectTime",
	_putRescheduletime:"api/v1/serviceProvider/appointments/reschedule",
	_markCompleted:"api/v1/serviceProvider/appointments/markCompleteVerifyOtp",
	_ratingUser:"api/v1/serviceProvider/appointments/reviewToUser",

	_getHelp:"api/v1/serviceProvider/help",
	_addHelp:"api/v1/serviceProvider/help",
	_helpStatusChat:"api/v1/serviceProvider/helpStatusChat",
	_getHelpDetails:"api/v1/serviceProvider/helpDetails",
	_postHelpChat:"api/v1/serviceProvider/helpChat",

	
	
	_getSubjectlist:"api/v1/serviceProvider/adminSubject",
	_updatedProfile:"api/v1/serviceProvider/updateProfile",


	_verifyEmail: 'api/v1/user/verifyEmail',
	_resendOtp: 'api/v1/user/resendEmailForVerification',
	_userType: 'api/v1/user/userType',
	_user: 'api/v1/user',
	_userById: 'api/v1/user/userDetail',
	_getDesignations: 'api/v1/user/getDesignations',
	_defaultSetting: 'api/v1/user/defaultSetting',
	_verifyForgetPasswordOtp: 'api/v1/user/verifyForgetPasswordOtp',
	_logOut: 'api/v1/user/logout',
	_adminSetting: 'api/v1/user/adminSetting',
	_changePassword: 'api/v1/user/changePassword',
	_getPlans: 'api/v1/licenses/getSubscriptionPlan',
	_getSubscribedPlans: 'api/v1/licenses/getUserSubscriptionPlan',
	_buyPlans: 'api/v1/licenses/buySubscriptionPlan',
	_vat: 'api/v1/user/verifyVAT',

	_getNotification: 'api/v1/notifications',
	_putNotification: 'api/v1/notifications/readAll',
	_putNoti: 'api/v1/user/notificationEnabled',
	// common api 
	_uploadPic: 'api/v1/common/getSignedURL',
	_countryList: 'api/v1/common/countries?limit=249&skip=0',

	_payment:'api/v1/payments/payment-token',
	_paymentSucess:'api/v1/payments/validatePayment',
	_paymentWallet:'api/v1/user/giftCards/paymentToken',
	
	//insights
	// _insights: 'api/v1/insights',
	// _sourceCountry: 'api/v1/insights/sourceCountry',
	// _sourceCountries: 'api/v1/common/sourceCountries',
	

	// _destinationCountry: 'api/v1/insights/destinationCountry',
	// _destinationCountries: 'api/v1/common/destinationCountries',

	// _insightResult: 'api/v1/insights/Result',
	// _getResult: 'api/v1/results',
	// _insightSingleResult: 'api/v1/results/singleResult',
	// _getSharedResult: 'api/v1/results/Detail',
	// _calculation: 'api/v1/insights/grossAmount',
	// _sharedCalculation: 'api/v1/insights/grossAmountShare',
	// _resultfav: 'api/v1/insights/resultFavorite',
	// _getGenerateCheck: 'api/v1/results/message',

	//team
	// _getTeam: 'api/v1/team',
	// _addUser: 'api/v1/team',
	// _editRole: 'api/v1/team/editRole',
	// _disableUser: 'api/v1/team/disable',
	// _purchaseHistory: 'api/v1/user/purchaseHistory',
	// _changePrimaryMember: 'api/v1/team/primaryMember',

	//lice dfdfgnese
	_getLicense: 'api/v1/licenses/getAllLicenses',
	_assignLicense: 'api/v1/licenses/assignLicense',
	_downloadInvoice: 'api/v1/user/exportInvoice',

	// offer
	_getOffers:'api/v1/offers',
	_getofferDetail:'api/v1/offers/details',
	// workingHours
	_getSalonWorkingHours:'api/v1/scheduling/getSalonWorkingHours',
	_businessHours:'api/v1/scheduling/businessHours',
	_updateBusinessHours:'api/v1/scheduling/updateBusinessHours',
	_manageHours:'api/v1/scheduling/manageHours',
	_updateManageHours:'api/v1/scheduling/updateManageHours',
	// general Instruction
	_wheelChairAccess:'api/v1/scheduling/wheelChairAccess',
	// close Hours
	_getClosingHours:'api/v1/scheduling/getClosingHours',
	_closingHours:'api/v1/scheduling/closingHours',
	_workingHourSalon:'api/v1/scheduling/workingHourSalon',
	_updateClosingHours:'api/v1/scheduling/updateClosingHours',
	_deleteClosingHours:'api/v1/scheduling/deleteClosingHours',

	_contactAdmin:"api/v1/serviceProvider/contact-to-admin",
	_updateProfile:"api/v1/serviceProvider/updateProfile",
	_changePasswordService:"api/v1/serviceProvider/changePassword",
	_clientList:"api/v1/serviceProvider/clientList",
	_internalNotes:"api/v1/serviceProvider/internalNotes",
	_getClientDetails:"api/v1/serviceProvider/getClientDetails",
	_clientReviewList:"api/v1/serviceProvider/clientReviewList",
	_verifyDeletingOtp:"api/v1/serviceProvider/verifyDeletingOtp",
	_deleteUserAccount:"/api/v1/serviceProvider/deleteUserAccount",
	_notificationEnabled:"api/v1/serviceProvider/notifications/notificationEnabled",
	_notifications:"api/v1/serviceProvider/notifications",
	_markAllRead:"api/v1/serviceProvider/notifications/markAllRead",
	_markRead:"api/v1/serviceProvider/notifications/markRead",

	_socialLogin:"api/v1/social-auth",
	_reviewAboutYou:"api/v1/serviceProvider/reviewAboutYou",
	_reportingReason:"api/v1/serviceProvider/reportingReason",
	_reviewAboutYouReport:"api/v1/serviceProvider/reviewAboutYouReport",
	_reviewByYou:"api/v1/serviceProvider/reviewByYou",
	_reviewAboutYouReply:"api/v1/serviceProvider/reviewAboutYouReply",
	_getFaq:"api/v1/serviceProvider/FAQlist",
	_getAbout:"api/v1/serviceProvider/aboutUs",
	_calendar:"api/v1/serviceProvider/calendar",

	_getReports:"api/v1/reports",
	_getSales:"api/v1/reports/sales",
	_getDashbaord:'api/v1/dashboard',
	_getDashbaordchart:'api/v1/dashboard/appointmentsCount',
	_getBookedServices:'/api/v1/dashboard/getBookedServices',
	_getMember :"api/v1/reports/getMembers",
	_getClients :"api/v1/reports/getClients",
	_logout:"api/v1/serviceProvider/logout",
	_updateDeviceToken:"api/v1/serviceProvider/updateDeviceToken"
}
