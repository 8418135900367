import { Component, OnInit } from '@angular/core';
import { DataSharingService } from "../../services/data.share.service";
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-mobile-privacy',
  templateUrl: './mobile-privacy.component.html',
  styleUrls: ['./mobile-privacy.component.css']
})
export class MobilePrivacyComponent implements OnInit {
  language:any='1'
  type:any = 1
  constructor(private datasharing: DataSharingService, private route: ActivatedRoute) { 
    this.language = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    localStorage.setItem('language', this.language)
    let languageSet =  this.type == 1 ? 'sp' :  this.type == 2 ? 'en' :'pt'
    this.datasharing.languageChange(languageSet);
  }
  languageChange(language, type){
    this.language = language
    this.type = type
    localStorage.setItem('language', this.type)
    sessionStorage.setItem('language', this.type); 
    let languageSet =  this.type == 1 ? 'sp' :  this.type == 2 ? 'en' :'pt'
    this.datasharing.languageChange(languageSet);
  
  }

}
