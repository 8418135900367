import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-privacy',
  templateUrl: './web-privacy.component.html',
  styleUrls: ['./web-privacy.component.css']
})
export class WebPrivacyComponent implements OnInit {
  language1:any=1;
  constructor() { 
    
    let language:any =localStorage.getItem('language')
    this.language1=language== 1?1:2
  }

  ngOnInit(): void {
    console.log(this.language1)
  }

}
