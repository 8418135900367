import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit {
  languageCheck:any
  constructor() { }

  ngOnInit(): void {
    this.languageCheck = localStorage.getItem('language')
  }

}
