<div class="terms-conditions" *ngIf="language==1 || language=='1'">
    <div class="smallcontainer container">
        <div class="termsWrap">
            <h1>Cómo Eliminar Tu Cuenta en BUKI</h1>
            <p>
                Si deseas eliminar tu cuenta en BUKI, sigue los sencillos pasos a continuación. Ten en cuenta que la eliminación de tu cuenta es permanente y eliminará toda la información relacionada con tu perfil en la aplicación.</p>

            <h1> Pasos para Eliminar Tu Cuenta </h1>
            <ol class="decimal">
                <li>
                    <h5>Abre la Aplicación de BUKI</h5>
                    <p>Ve a tu perfil tocando el ícono de "Perfil" en la parte inferior de la pantalla.</p>
                </li>
                <li>
                    <h5>Navega a Configuración de Cuenta</h5>
                    <p>En la sección de perfil, selecciona Administrar tu cuenta.</p>
                </li>
                <li>
                    <h5>Selecciona "Eliminar Cuenta"</h5>
                    <p>Desplázate hacia abajo y toca la opción de <b>Eliminar cuenta</b>. Serás redirigido a una página que explica las consecuencias de la eliminación de la cuenta.</p>
                </li>
                <li>
                    <h5>Elige un Motivo para Eliminar tu Cuenta</h5>
                    <p>Selecciona un motivo para eliminar tu cuenta de las opciones proporcionadas:</p>
                </li>
                <li>
                    <h5>Solicita un OTP para Verificación</h5>
                    <p>Después de seleccionar un motivo, toca <b>Solicitar OTP para eliminar</b>. Se enviará una Contraseña de Un Solo Uso (OTP) a tu número de teléfono registrado para confirmar tu acción.</p>
                </li>
                <li>
                    <h5>Ingresa el OTP</h5>
                    <p>Ingresa el OTP que recibiste en tu teléfono. Este paso verifica tu identidad y confirma la solicitud de eliminación.</p>
                </li>
                <li>
                    <h5>Completa la Eliminación de la Cuenta</h5>
                    <p>Una vez que se verifique el OTP, tu cuenta será eliminada permanentemente de BUKI. Todos los datos asociados serán eliminados y ya no tendrás acceso a tu perfil.</p>
                </li>
               
            </ol>
        </div>
    </div>
</div>
<div class="terms-conditions" *ngIf="language==2 || language=='2'">
    <div class="smallcontainer container">
        <div class="termsWrap">
            <h1>How to Delete Your Account on BUKI</h1>
            <p>
                If you want to delete your account on BUKI, follow the simple steps below. Please note that deleting your account is permanent and will remove all information related to your profile in the app.
            </p>
    
            <h1>Steps to Delete Your Account</h1>
            <ol class="decimal">
                <li>
                    <h5>Open the BUKI App</h5>
                    <p>Go to your profile by tapping the "Profile" icon at the bottom of the screen.</p>
                </li>
                <li>
                    <h5>Navigate to Account Settings</h5>
                    <p>In the profile section, select Manage Your Account.</p>
                </li>
                <li>
                    <h5>Select "Delete Account"</h5>
                    <p>Scroll down and tap the <b>Delete Account</b> option. You will be redirected to a page that explains the consequences of deleting your account.</p>
                </li>
                <li>
                    <h5>Choose a Reason for Deleting Your Account</h5>
                    <p>Select a reason for deleting your account from the provided options:</p>
                </li>
                <li>
                    <h5>Request an OTP for Verification</h5>
                    <p>After selecting a reason, tap <b>Request OTP to Delete</b>. A One-Time Password (OTP) will be sent to your registered phone number to confirm your action.</p>
                </li>
                <li>
                    <h5>Enter the OTP</h5>
                    <p>Enter the OTP you received on your phone. This step verifies your identity and confirms the deletion request.</p>
                </li>
                <li>
                    <h5>Complete the Account Deletion</h5>
                    <p>Once the OTP is verified, your account will be permanently deleted from BUKI. All associated data will be removed, and you will no longer have access to your profile.</p>
                </li>
            </ol>
        </div>
    </div>
    
</div>