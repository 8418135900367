<div class="outer-header fixed-header {{userData?.userDetails?.webFinished == 0 ? 'outer': 'innerHeader'}}">
  <div class="outer-logo {{userData?.userDetails?.webFinished == 1 ? 'ex': ''}}" *ngIf="!showManageBar">
   
    <img src="../../../assets/images/ic_logo2.svg" alt="logo" class="{{userData?.userDetails?.webFinished == 1 ? 'cursor' :''}}" [routerLink]="userData?.userDetails?.webFinished == 1 ? '/dashboard' :''">
  </div>

  <div class="header-right" [ngClass]="showManageBar?'manage-bar-hide':''"
    *ngIf="userData?.userDetails?.isCompleteProfile == 1 && urlCheck != '/introduction'">
    <ul class="mobile-menu" *ngIf="userData?.userDetails?.webFinished != 0 && !showManageBar ">
      <li>
        <a class="nav-link sidebar-toggler js-sidebar-toggler" href="javascript:;" (click)="sideToggle()">
          <span class="icon-bar line-first"></span>
          <span class="icon-bar line-second"></span>
          <span class="icon-bar line-third"></span>
        </a>
      </li>
    </ul>
    <div class="" *ngIf="showManageBar">
      <img src="../../../assets/images/ic_logo2.svg" alt="logo" class="{{userData?.userDetails?.webFinished == 1 ? 'cursor' :''}}"  (click)="leaveManage()"  [routerLink]="userData?.userDetails?.webFinished == 1 ? '/dashboard' :''">
    </div>
    <ul class="nav navbar-toolbar">
      <li class="notification" ngbDropdown [autoClose]="true" #myDrop="ngbDropdown"
        *ngIf="userData?.userDetails?.webFinished == 1 && userData?.userDetails?.isApproved == 2">
        <a class="notification-bing" id="notification-bing" ngbDropdownToggle>
          <span *ngIf="notificationList?.unreadNotificationsCount>0" class="notice-label"> </span>
          <img src="../../../assets/images/notification-bing.svg" alt="image">
        </a>

        <div class="dropdown-menu notification-bing-content dropdown-menu-right" ngbDropdownMenu
          aria-labelledby="notification-bing">
          <div class="dropdown-arrow"></div>
          <div class="noti-head" *ngIf="notificationList">
            <strong>{{'Notifications' | i18n }} ({{notificationList.unreadNotificationsCount}}) </strong>
            <a class="mark-all" (click)="markAllRead()">{{'Mark all as read' | i18n }}  </a>
          </div>

          <div class="tab-list">
            <ul>
              <li [ngClass]="currentTab==0?'active':''" (click)="handleChangeTab(0)">  {{'All' | i18n }}</li>
              <li [ngClass]="currentTab==1?'active':''" (click)="handleChangeTab(1)">  {{'Appointment' | i18n }}</li>
              <li [ngClass]="currentTab==2?'active':''" (click)="handleChangeTab(2)">  {{'Reviews' | i18n }}</li>
              <li [ngClass]="currentTab==3?'active':''" (click)="handleChangeTab(3)">  {{'Payment' | i18n }}</li>
            </ul>

            <ul class="noti-list" *ngIf="notificationList">
              <li [ngClass]="notification.status==0?'unread-hlgt':''"
                (click)="myDrop.close();navigateNotification(notification)"
                *ngFor="let notification of notificationList.data.rows">
                <span class="clipboard-green"><img onerror="this.src='assets/images/ic_logo2.svg'"
                    [src]="notification.image" alt="image"></span>
                <!-- <h5> {{notification.title}}</h5>
                <p> {{notification.message}} </p> -->
                <h5> {{language==2?(notification.title?notification.title:notification.titleSpanish?notification.titleSpanish:notification.titlePortugese):language==1?(notification.titleSpanish?notification.titleSpanish:notification.title?notification.title:notification.titlePortugese):(notification.titlePortugese?notification.titlePortugese:notification.title?notification.title:notification.titleSpanish)}}</h5>
                <p> {{language==2?(notification.message?notification.message:notification.messageSpanish?notification.messageSpanish:notification.messagePortugese):language==1?(notification.messageSpanish?notification.messageSpanish:notification.message?notification.message:notification.messagePortugese):(notification.messagePortugese?notification.messagePortugese:notification.message?notification.message:notification.messageSpanish)}}
                   </p>
                <p class="date-time {{!(notification.createdAt?.toDateString()==today?.toDateString())?'marging':''}}"> {{(notification.createdAt?.toDateString()==today?.toDateString())
                  ?(notification.createdAt | date:'hh:mm a') :
                  ( notification.createdAt | date:'dd MMM, yyyy')}} </p>
              </li>
              <!-- 
              <li>
                <span class="clipboard-orange"><img src="../../../assets/images/ic_star-white.svg" alt="image"></span>
                <h5> Appointment confirmed </h5>
                <p> 10 AM today haircut for Berry Sce booked with John Appleased. </p>
                <p class="date-time"> 11:35 AM </p>
              </li>

              <li>
                <span class="clipboard-orange"><img src="../../../assets/images/ic_star-white.svg" alt="image"></span>
                <h5> Appointment confirmed </h5>
                <p> 10 AM today haircut for Berry Sce booked with John Appleased. </p>
                <p class="date-time"> 11:35 AM </p>
              </li>
 -->

            </ul>


            <div class="all_notifications">
              <a class="notifications-btn" routerLink="notifications" (click)="myDrop.close();hideSideBar()">  {{'See all notifications' | i18n }}
              </a>
            </div>

          </div>

        </div>

      </li>

      <li class="dropdown dropdown-user profile-drop" ngbDropdown [autoClose]="true" #myDrop="ngbDropdown">
        <a class="dropdown-toggle" id="dropdownBasic2" ngbDropdownToggle>
          <img src="assets/images/ic_add_image.svg" alt="image"
            *ngIf="userData?.userDetails?.businessProfile?.getBusinessProfile?.businessLogo == null && userData?.userDetails?.saloonRole == 1">
          <img [src]="BaseUrl + userData?.userDetails?.businessProfile?.getBusinessProfile?.businessLogo" alt="image"
            *ngIf="userData?.userDetails?.businessProfile?.getBusinessProfile?.businessLogo != null && userData?.userDetails?.saloonRole == 1">

          <img src="assets/images/ic_add_image.svg" alt="image"
            *ngIf="(userData?.userDetails?.profileImage == '')   && userData?.userDetails?.saloonRole == 2">
          <img [src]="BaseUrl + userData?.userDetails?.profileImage" alt="image"
            *ngIf="(userData?.userDetails?.profileImage != '') && userData?.userDetails?.saloonRole == 2">

          <span>{{userData?.userDetails?.name || ''}} </span>
        </a>
        <!-- <img [src]="(BaseUrl + image.image)" alt=""> -->
        <div class="dropdown-menu dropdown-arrow dropdown-menu-right admin-dropdown-menu" ngbDropdownMenu
          aria-labelledby="dropdownBasic2">
          <div class="dropdown-arrow"></div>
          <ul class="features-menu">
            <li *ngIf="userData?.userDetails?.webFinished == 1 && userData?.userDetails?.isApproved == 2"> <a
                routerLink="/manage/personal-information" (click)="showManageSideBar();myDrop.close();"> {{'Manage
                your account' | i18n }}</a></li>
            <li *ngIf="userData?.userDetails?.webFinished == 1 && userData?.userDetails?.isApproved == 2"> <a
                routerLink="/account-settings" (click)="hideSideBar();myDrop.close();">
                {{'Account settings' | i18n }}</a></li>
            <li (click)="myDrop.close();logout()"> <a>{{'Logout' | i18n }} </a></li>
          </ul>
        </div>
      </li>
    </ul>

    <!-- END TOP-RIGHT TOOLBAR-->

  </div>
</div>


<div class="noticationOuter">
  <div class="notificationlocation" [ngClass]="{'openCls': selectedIndex == 1}">
    <div class="modal-header">
      <!-- <div class="closeButton" (click)="close()">
        <span aria-hidden="true" class="visually-hidden"><img src="../../../../assets/images/ic_cross.svg"></span>
      </div> -->
      <div class="title mb-2 mt-1">
        <p class="header">{{notificationDetails?.title}} </p>
      </div>
    </div>
    <div class="modal-body">
      <p>{{notificationDetails?.message}}</p>
      <div class="form-group mb-0">
      <button type="submit" class="btn button-primary small" (click)="close()">
        {{'Ok' | i18n}}</button>
    </div>
    </div>
    </div>


</div>


<router-outlet></router-outlet>