import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./services/auth.guard";
import { NotFoundComponent } from "./extrnal/not-found/not-found.component";
import { InternalHeaderComponent } from "./common/internal-header/internal-header.component";
import { MobileComponent } from './../app/extrnal/mobile/mobile.component';
import { MobilePrivacyComponent } from './../app/extrnal/mobile-privacy/mobile-privacy.component';
import { MobileAboutComponent } from './../app/extrnal/mobile-about/mobile-about.component';
import { WebPrivacyComponent } from './../app/extrnal/web-privacy/web-privacy.component';
import { WebTermsComponent } from './../app/extrnal/web-terms/web-terms.component';
import { MobileFaqComponent } from "./extrnal/mobile-faq/mobile-faq.component";
import { PaymentMobileComponent } from "./extrnal/payment-mobile/payment-mobile.component";
import { PaymentSuccessComponent } from './extrnal/payment-success/payment-success.component';
import { PaymentErrorComponent } from "./extrnal/payment-error/payment-error.component";
import { PaymentWalletMobileComponent } from "./extrnal/payment-wallet-mobile/payment-wallet-mobile.component";
import { DeleteContantComponent } from './extrnal/delete-contant/delete-contant.component'

/*new*/
const routes: Routes = [
  { path: '',   redirectTo: 'login', pathMatch: 'full' },
  { path: '',  loadChildren: () => import('./extrnal/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '', canActivate: [AuthGuard], component: InternalHeaderComponent, children: [{
      path: "", loadChildren: () =>
        import("./internal/internal.module").then((m) => m.InternalModule),
    },]
  },
  { path: 'mobile-terms/:id', component: MobileComponent },
  { path: 'mobile-privacy/:id', component: MobilePrivacyComponent },
  { path: 'privacy', component: WebPrivacyComponent },
  { path: 'paymentMobile', component: PaymentMobileComponent },
  { path: 'paymentwalletMobile', component: PaymentWalletMobileComponent },
  
  { path: 'paymentSuccess', component: PaymentSuccessComponent },
  { path: 'paymentError', component: PaymentErrorComponent },
  
  {
    path: "about/:id",
    component: MobileAboutComponent,
  },
  {
    path: "faq/:id",
    component: MobileFaqComponent,
  },
  { path: 'terms', component: WebTermsComponent },
  { path:'delete-terms/:id', component:DeleteContantComponent},
  { path: '**', component: NotFoundComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
