<div class=" content-wrapper faq" >

    <div class="right-side margin-auto ">
      <div class="container business-section">
  
        <div id="faq" class="faq">

          <div class="title mb-4" >
        
          </div>
          
          <!-- <div class="faq-list">
            <ul>
              <li *ngFor="let data of faqList; let i = index">
                <div class="accordion {{showThis ==  i && show == true ? 'active' :''}}" >
                  <div class="faqHeader" (click)="showThis = i;show = true">
                    {{langugaeCheck(data)}}
                  </div>
                  <div class="card" *ngIf="showThis ==  i && show == true">
                    {{langugaeCheck2(data)}}
                  </div>
                </div>
              </li>
  
            </ul>
          </div> -->
          <div class="faq-list" >
            <ul>
              <li *ngFor="let data of faqList">
                
                <accordion class="accordion">
                  <accordion-group class="card" heading={{langugaeCheck(data)}}>
                    {{langugaeCheck2(data)}}
                  </accordion-group>
                </accordion>
              </li>
              <div class="no-data-found" *ngIf="faqList?.length == 0">
                {{text}}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>