<div class="outer-header fixed-header outer">
    <div class="outer-logo">
      <img src="../../../assets/images/ic_logo2.svg" alt="logo">
    </div>

  </div>
  <div class="terms-conditions">
    <div class="smallcontainer container">
        <div class="termsWrap">
            <div class="manage mt-3 sm-full-sr">
                <!-- START PAGE CONTENT-->
                <div class="right-side margin-auto">
                    <div class="container business-section">
                        
                       <div class="row privacy" *ngIf="language1==1">
                        <div class="col-sm-12">
                                <div class="title mb-4">
                                    <p class="header-2"> TÉRMINOS Y CONDICIONES: ESTABLECIMIENTOS COMERCIALESY FREELANCERS</p>
                                </div>
                                
                           <h6 class="heading-5"> Aspectos Generales</h6>
                                 <p class="para-purple">Buki Peluplanner S.A.C. identificada con R.U.C. N° 20610927379, con domicilio en Av. Javier Prado Este N° 2248, distrito de San Borja, provincia y departamento de Lima, es una empresa constituida bajo las leyes de la Republica del Perú titular de esta plataforma digital a quien, para todos los efectos de los presentes términos y condiciones, en adelante, se le denominará “Buki”.  </p>
                       
                        </div>
            
                       </div>
                       
                       <div class="col-sm-12" *ngIf="language1==1">
                           <h6 class="heading-5"> Definiciones</h6>
                           <p class="para-p">En la interpretación y aplicación de los presentes términos y condiciones se considerará las siguientes definiciones:</p>
                           <ul class="content-list small">
                            <li><b>Acuerdo de Afiliación:</b> acuerdo de naturaleza vinculante que rige las condiciones del uso de la plataforma Buki entre los Establecimientos Comerciales, los Freelancers y Buki.</li>
                            <li><b>Datos de acceso:</b> nombre de usuario y contraseña empleados para el registro y el inicio de sesión en la cuenta creada por el Usuario en el software de afiliados..</li>
                            <li><b>Directrices de Reseñas:</b> documento que contiene las directrices para el intercambio de comunicaciones entre los Establecimientos Comerciales o Freelancers y los Usuarios de la plataforma Buki con motivo de los comentarios y calificaciones otorgadas a   los servicios ofrecidos por estos.</li>
                            <li><b>Establecimiento Comercial:</b> centro de belleza afiliado a la plataforma Buki para la oferta de servicios de belleza, estéticos y spa a los Usuarios.</li>
                            <li><b>Freelancer: profesional de la belleza o estilista afiliado a la plataforma Buki que presta servicios de belleza y/o estéticos de manera independiente a los Usuarios.  </b></li>   
                            
                <li><b>Registro:</b>proceso mediante el cual una persona natural ingresa su información personal al formulario virtual con la finalidad de crear una cuenta en la plataforma Buki. </li>

                <li><b>Servicio:</b>labor de intermediación realizada por la plataforma digital para facilitar la relación de consumo entre los Usuarios y los centros de belleza afiliados a la plataforma.</li>
                <li><b>Orden de servicio:</b>solicitud requerida por el Usuario respecto de un servicio provisto por un centro de belleza o Freelancer luego de haber sido confirmado el pago de la misma.</li>
                <li><b>Usuario:</b>persona natural que tiene registrada una cuenta en la plataforma Buki.</li>
                        </ul>
                        <h6 class="heading-5">Funcionamiento de la plataforma</h6>

            <p class="para-p">La plataforma Buki es una herramienta tecnológica que busca facilitar la intermediación entre los Usuarios y los Establecimientos Comerciales o Freelancers con la finalidad de que los primeros puedan contratar los servicios ofrecidos por estos últimos. En atención a ello, el usuario reconoce que en todo momento Buki actúa como un tercero intermediario ajeno a las relaciones de consumo celebradas entre los Usuarios y los Establecimientos Comerciales o Freelancers. </p>
            <h6 class="heading-5">Condiciones del Servicio:</h6>
            <p class="para-p">La plataforma Buki ofrece diversos servicios a los Establecimientos Comerciales y Freelancers que les permiten gestionar sus citas con los Usuarios de la plataforma y promocionar sus diferentes servicios a través de la misma. Los servicios contratados con la plataforma Buki se encontrarán definidos en el Acuerdo de Afiliación celebrado entre el Establecimiento Comercial o el Freelacer y Buki.   </p>
            <h6 class="heading-5">Servicios:</h6>
            <p class="para-p">La plataforma Buki pone a disposición de los Establecimientos Comerciales y Freelancers, principalmente, dos servicios cuyas condiciones se encuentran especificadas en el Acuerdo de Afiliación:</p>
            <ul class="content-list small">
                <li><p class="para-p"><b>Software de afiliados:</b> consistente en un software de gestión de registro de citas con diversas funciones para mejorar la gestión de la agenda con clientes y potenciar el desarrollo del negocio. El software de afiliados comprende funciones y herramientas de punto de venta que el Establecimiento Comercial o el Freelancer pueden emplear para incrementar su alcance comercial con los Usuarios de la plataforma Buki.</p>
                <p class="para-p">En caso de que el Establecimiento Comercial o el Freelancer hayan adquirido un software de afiliados se le concederá una licencia de uso personal, exclusiva, intransferible y revocable para poder emplear el software en la gestión de su negocio. Buki se reserva el derecho de hacer seguimiento al uso del software de afiliados mediante acceso de administrador con la finalidad de brindar soporte e identificar oportunidades de mejora en su desarrollo.</p>
            <p class="para-p">El Establecimiento Comercial o el Freelancer podrá crear una cuenta en el software de afiliados y deberán generar sus propios datos de acceso bajo un nombre de usuario y contraseña para poder acceder al mismo. La cuenta creada será personal e intransferible siendo deber del Establecimiento Comercial y el Freelancer no compartir los datos de acceso con terceros que no hayan adquirido el software de afiliados con Buki.</p>
        <p class="para-p">Buki no ofrece ningún tipo de garantía sobre las características, funcionalidad y rendimiento del software de afiliados, así como tampoco respecto a la exactitud de la información o los resultados obtenidos a través del mismo. De igual manera, Buki no se responsabiliza por las pérdidas que puedan presentar los Establecimientos Comerciales o Freelancers por fallos o interrupciones en el servicio de software de salón.</p>
    <p class="para-p">Sin perjuicio de ello, Buki se compromete a tomar las medidas que razonablemente se encuentren dentro de su ámbito de control para mejorar los niveles de funcionalidad del software de afiliados en caso este no alcance un estándar adecuado para la función a cumplir.</p>
</li>
<li><p class="para-p"><b>Plataforma Buki:</b> plataforma virtual de reservas de citas con centros o profesionales de belleza, ubicado en nuestra App Buki, donde los Establecimientos Comerciales o los Freelancers pueden anunciar sus servicios de belleza con un gran número de potenciales clientes quienes descargan el aplicativo directamente en sus smartphones o teléfonos inteligentes.</p></li>
                </ul>  
                <h6 class="heading-5">Comisiones y tarifas:</h6>   
                <p class="para-p">El Establecimiento Comercial o el Freelancer pagará a Buki una comisión por el Servicio brindado a través del uso de la plataforma Buki para la promoción de sus servicios de belleza a los Usuarios. La comisión que cobrará Buki por este Servicio será cobrada al Establecimiento Comercial o al Freelancer directamente del importe pagado por el Usuario al momento de realizar una Reserva a través de la plataforma Buki.  </p>
                <p class="para-p">El importe de la comisión, así como la forma y oportunidad de pago de la misma se encuentran detallados en el Acuerdo de Afiliación suscrito con el Establecimiento Comercial o el Freelancer, el cual podrá ser revisado y modificado por Buki de forma periódica. Para dichos efectos, el Acuerdo de Afiliación tendrá el mismo efecto vinculante que los presentes términos y condiciones suscritos por el Establecimiento Comercial o el Freelancer. </p>
                <p class="para-p">La comisión que el Establecimiento o el Freelancer pagará a Buki por el Servicio no comprende el pago que se debitará por el uso de la pasarela de pago y que será cobrado directamente por el proveedor de dicho servicio en atención a las propias condiciones de su contrato de servicios. </p>
                <p class="para-p">El Establecimiento Comercial o el Freelancer serán responsables de cargar y cobrar el Impuesto General a las Ventas (18%) sobre el valor total de la Reserva gestionada a través de la plataforma Buki. Asimismo, el Establecimiento Comercial o el Freelancer se comprometen a atender cualquier tipo de requerimiento de información y/o fiscalización realizada por las autoridades tributarias o fiscales con relación a los servicios ofertados a través de la plataforma Buki.</p>
                <p class="para-p">Buki podrá requerir al Establecimiento Comercial o al Freelancer copias de los comprobantes de pago correspondientes a los servicios brindados a través de la plataforma Buki, de las declaraciones y certificados de pago correspondientes a los impuestos pagados por los mismos y/o cualquier certificado de exención de impuestos a los que hubiera lugar. </p>
                <p class="para-p">El Establecimiento Comercial o el Freelancer serán responsables de los reembolsos, recargos, gastos bancarios y/o administrativos a los que hubiera lugar en caso de que no haya informado con la debida anticipación a Buki  los cambios en sus datos de pago y/o bancarios de ser el caso.</p>
                <p class="para-p">En caso de que Buki detecte que el Establecimiento Comercial o el Freelancer hayan intentado evitar el pago de cualquier comisión de manera fraudulenta, Buki se reserva el derecho de exigir y/o retener cualquier pago que se adeude. Sin perjuicio de ello, Buki podrá suspender, cancelar, inhabilitar la cuenta del Establecimiento Comercial o del Freelancer a sola discreción hasta que se verifique el cumplimiento del pago de los importes adeudados.  </p>
                <p class="para-p">Buki se reserva el derecho de modificar en cualquier momento el importe de sus comisiones y tarifas lo cual le será comunicado al Establecimiento Comercial o al Freelancer con 30 días calendario de anticipación. Transcurrido el referido plazo sin que el Establecimiento Comercial o el Freelancer haya manifestado su voluntad de prescindir de los servicios de Buki, se entenderá que este se encuentra de acuerdo con las modificaciones a las comisiones y tarifas encontrándose obligado al pago de las mismas en las oportunidades acordadas en el Acuerdo de Afiliación. </p>
                <h6 class="heading-5">Reservas y cancelaciones:</h6>
                <p class="para-p">Los Usuarios podrán agendar una cita con los Establecimientos Comerciales o los Freelancers para recibir  los servicios de belleza ofrecidos  en la plataforma Buki constituyendo cada una de estas citas una Reserva para efectos de la interpretación de los presentes términos y condiciones</p>
                <p class="para-p">Cuando el Establecimiento Comercial o el Freelancer aceptan la Reserva de un Usuario se comprometen a brindar los servicios de belleza en las mismas condiciones en que han sido promocionados y publicitados a través de la plataforma Buki. Los Establecimientos Comerciales y los Freelancers serán los únicos responsables de que los servicios de belleza se brinden en la forma, modo y oportunidad en que han sido ofertados a los Usuarios.</p>
                <p class="para-p">Los Establecimientos Comerciales y los Freelancers aceptarán las Reservas realizadas a través de la plataforma Buki en atención a la disponibilidad de su personal y de recursos. Sin perjuicio de ello, se comprometen a realizar todos los esfuerzos necesarios para aceptar la mayor cantidad de Reservas que sean realizadas por los Usuarios a través de la plataforma Buki.  </p>
                <p class="para-p">Buki no reembolsará al Usuario en los casos que la cancelación de la Reserva se haya producido dentro de las 24 horas previas a la hora fijada para la Reserva debitándose la Comisión por el Servicio y el cargo correspondiente por el uso de la pasarela de pagos. En este caso, Buki, trasladará al Establecimiento Comercial y/o Freelancer el importe correspondiente al cobro por el servicio contratado a fin de que bajo su propio costo y responsabilidad realice el reembolso correspondiente al servicio de belleza contratado con los Usuarios. Sin embargo, Buki reembolsará al Usuario la totalidad del importe pagado por la Reserva cuando la cancelación de la misma se haga con una anticipación mayor a 24 horas de la hora fijada para la Reserva. </p>
                <p class="para-p">Sin perjuicio de lo señalado, en cualquier momento, los Establecimientos Comerciales y los Freelancers tendrán la posibilidad de negociar directa y voluntariamente con los Usuarios la cancelación y reembolso de una Reserva en atención a sus propias políticas comerciales. En dicho supuesto, el Establecimiento Comercial o el Freelancer asumirá bajo su propia responsabilidad el reembolso del importe correspondiente a sus servicios, descontando la Comisión por el Servicio de Buki y el importe correspondiente al servicio de pasarela de pago.</p>
                <h6 class="heading-5">Reseñas y calificaciones:</h6>
                <p class="para-p">La plataforma Buki brinda la posibilidad de que los Usuarios puedan realizar la búsqueda de los servicios que ofrecen los Establecimientos Comerciales y los Freelancers a través de un listado que contiene los nombres de los establecimientos afiliados. La búsqueda y clasificación de los Establecimientos Comerciales y Freelancers responde a diversos factores que permiten que los Usuarios puedan encontrar los servicios de belleza que más se ajusten a sus necesidades. Los Usuarios tendrán la posibilidad de realizar la búsqueda de los servicios de belleza a través de filtros con diversos criterios de búsqueda que les permita optimizar los resultados esperados.  </p>
                <p class="para-p">La plataforma Buki otorga la posibilidad de que los Usuarios puedan dejar reseñas sobre los servicios brindados por los Establecimientos Comerciales y los Freelancers a las que podrán acceder de manera permanente. El Establecimiento Comercial y el Freelancer exime de responsabilidad a Buki por cualquier pérdida que pudiera haber presentado con motivo de las reseñas y calificaciones que los Usuarios realizan sobre cada uno de sus servicios.</p>
                <P class="para-p">El Establecimiento Comercial y el Freelancer no mantendrá ningún derecho sobre las reseñas, calificaciones y/o comentario de los Usuarios de la plataforma Buki y tampoco tendrá derecho a solicitar copia parcial o total de estas.</P>
                <P class="para-p">El Establecimiento Comercial y el Freelancer pueden responder a los comentarios y opiniones que los Usuarios brinden sobre sus servicios de belleza en atención a la Directrices sobre Reseñas a las que podrá acceder luego de su afiliación a la plataforma Buki. </P>
                <P class="para-p">Buki se reserva el derecho de pedir al Establecimiento Comercial o al Freelancer que revise y/o modifique sus respuestas a los Usuarios antes o después de publicarlas. Sin perjuicio de ello, Buki se reserva el derecho de eliminar cualquier respuesta a los Usuarios que no se ajuste a las disposiciones de la Directiva sobre Reseñas.</P>
                <P class="para-p">En caso de que el Establecimiento Comercial o el Freelancer considere difamatorio un comentario o reseña de un Usuario sobre su establecimiento, personal profesional, o cualquiera de sus servicios o que incluso este pueda vulnerar sus derechos o los de terceros, podrá comunicar dicha circunstancia a Buki para la investigación y evaluación del caso. Luego de la investigación realizada Buki adoptará las medidas que considere necesarias para corregir la situación lo que podrá incluir la eliminación del comentario del Usuario de la plataforma.</P>
                <h6 class="heading-5">Pasarela de pagos: </h6>
                <p class="para-p">Un proveedor externo brindará los servicios de pasarela de pago que empleará la plataforma Buki para procesar el pago de los servicios de belleza ofrecidos por los Establecimientos Comerciales y los Freelancers.</p>
                <P class="para-p">Con la aceptación de los presentes términos y condiciones, los Establecimientos Comerciales y los Freelancers otorgan su autorización para que la tarifa fijada por el servicio de pasarela de pago sea descontada y debitada del importe total de la Reserva la cual será cobrada directamente por el proveedor a cargo de brindar este servicio. Asimismo, los Establecimientos Comerciales y los Freelancers declaran estar de acuerdo con que los servicios de pasarela de pago se brinden conforme a las condiciones pactadas en el contrato de servicios celebrado entre el proveedor externo de pasarela de pago y Buki. </P>
                <P class="para-p">Los Establecimientos Comerciales y los Freelancers se comprometen a cumplir con todas las condiciones del servicio de pasarela de pago para facilitar la gestión de las Reservas en la plataforma Buki.</P>
                <P class="para-p">En caso de que sea necesario, los Establecimientos Comerciales y los Freelancers se comprometen a facilitar a Buki toda la información sobre su negocio que les sea requerida y autorizan a Buki a compartirla, con la finalidad de que pueda gestionar la habilitación de los servicios de pasarela de pago asociados a su cuenta.  </P>
                <h6 class="heading-5">Obligaciones del Establecimiento Comercial y/o el Freelancer:</h6>
                <p class="para-p">Por medio de la aceptación de los presentes términos y condiciones el Establecimiento Comercial y/o el Freelancer se compromete de manera voluntaria, expresa e irrevocable a cumplir con las siguientes obligaciones:</p>
                <ul class="small decimal">
                    <li>No podrá sublicenciar la licencia de uso del software de afiliados a terceros ni transferirla, cederla o venderla.</li>
                    <li>Guardar la confidencialidad de sus datos de acceso al software de afiliados como son su nombre de usuario y su contraseña encontrándose prohibido de transmitirlos o cederlos a terceros. Asimismo, realizará todas las acciones razonables para impedir cualquier acceso no autorizado y en caso de que se produzca adoptar las medidas para repelerlo. Sin perjuicio de ello, deberá comunicar a Buki inmediatamente en caso tome conocimiento sobre la existencia o intento de un acceso no autorizado.</li>
                    <li>Mantendrá actualizados los datos de su representada registrados en su cuenta cuando estos sean modificados.</li>
                    <li>No podrá acceder al software de afiliados a través de datos correspondientes a otros Establecimientos Comerciales o Freelancers.</li>
                    <li>No manipulará ni sobeteará el software de afiliados a través de la introducción de virus, softwares, programas o mediante cualquier método tecnológico o análogo con fines ilícitos o fraudulentos que le puedan ocasionar un daño y/o afectar su normal funcionamiento.</li>
                    <li>A hacer que su nombre comercial, dirección y otros datos de identificación sean plenamente comunicados a los Usuarios de la plataforma Buki en todos los servicios que utilicen</li>
                    <li>No proporcionará a los Usuarios datos de contacto, referencias indirectas o enlaces a plataformas externas a Buki.</li>
                    <li>Garantizará que toda la información, documentación, fotografías, datos de contacto, directrices de marca, políticas de cancelación, condiciones de servicio y precios sea exacta, correcta, actualizada y no sea engañosa para los Usuarios. </li>
                    <li>Ofertar los servicios en la plataforma Buki al mejor precio disponible que ofrezca a través de su página web. Sin perjuicio de ello, el Establecimiento Comercial o el Freelancer podrán ofrecer precios más bajos y/u ofertas especiales a grupos cerrados (como programas de fidelización) o directamente en su establecimiento. </li>
                    <li>Obtener el consentimiento de los Usuarios para el envío de publicidad comercial, promociones y ofertas y conservar el registro de dichos consentimientos para compartirlos en caso sean requeridos por Buki.</li>
                    <li>No solicitar al Usuario que hagan reservas a través de otro medio que no sea la plataforma Buki o pedirle que cancele una reserva para volver a hacer una nueva directamente con el Establecimiento Comercial o el Freelancer.</li>
                    <li>Hacer periódicamente copias de seguridad de su información cuando utilice el software de afiliados. Buki no garantiza que todos los datos introducidos en el software de afiliados se encuentren respaldados. En consecuencia, Buki no será responsable por las pérdidas de información que se hayan producido por omitir dicha obligación. </li>
                    <li>No podrá emplear el software de afiliados con fines ilícitos o ilegales que pueda implicar la vulneración de derechos de terceros.</li>
                    <li>Proporcionará a Buki la información que le sea requerida sobre la naturaleza, características y condiciones de los servicios a ofertar a través de la plataforma, así como aquella relacionada a los profesionales de belleza de sus establecimientos con quienes los Usuarios agendarán sus citas. </li>
                    <li>Cumplirá con ejecutar de manera íntegra y oportuna los servicios de belleza que a través de la plataforma Buki hayan sido solicitados por los Usuarios.</li>
                    <li>Cumplirá a cabalidad con cualquier oferta o promoción realizada a través de la plataforma Buki conforme a los términos y condiciones publicitados. </li>
                    <li>Proporcionará a los Usuarios de la Plataforma Buki su política de cancelación y reembolso respecto a los servicios ofrecidos a través de esta.</li>
                    <li>Mantendrá un registro detallado sobre todas y cada una de las Reservas gestionadas a través de la plataforma Buki.</li>
                    <li>Cumplirá con proporcionar a Buki aquella información que le haya sido requerida respecto a cualquiera de las Reservas gestionadas a través de la plataforma.</li>
                    <li>Colaborará con Buki respecto a la investigación de cualquier operación sospechosa o irregular que haya podido presentarse en el uso de la plataforma. </li>
                    <li>Cumplirá en su integridad y de manera oportuna con el pago de las tarifas y/o comisiones previstas en el Acuerdo de Afiliación.</li>
                    <li>Adoptará las medidas que razonablemente se encuentren dentro de su ámbito de control para brindar un servicio idóneo a los Usuarios de la plataforma Buki.</li>
                </ul>
                <h6 class="heading-5">Garantías:</h6>
                <p class="para-p">Mediante la aceptación de los presentes términos y condiciones el Establecimiento Comercial y el Freelancer declaran y garantizan lo siguiente:</p>
                <ul class="content-list small">
                    <li>Que todo el contenido que comparta y/o publique en la plataforma de Buki es exacto y actualizado, no es ilegal, difamatorio, ofensivo ni contrario a las buenas costumbres y no infringe derechos de terceros. </li>
                    <li>Que cumple con todas las leyes y regulaciones sectoriales previstas para la prestación de sus servicios y se compromete a cumplir con aquellas que puedan entrar en vigencia durante la colaboración con la plataforma Buki</li>
                    <li>Que se compromete a cumplir con la regulación sobre publicidad comercial y de protección al consumidor vigente, sus modificatorias y textos sustitutorios en la promoción y ejecución de los servicios ofrecidos por medio de la plataforma Buki.</li>
                    <li>Que cumple con las regulaciones tributarias que regulan su actividad comercial. 
                    </li>
                    <li>Que cuenta con todas las licencias, permisos y autorizaciones emitidas por las autoridades competentes para el desarrollo de su actividad comercial.</li>
                    <li>Que cumple con la legislación sobre protección de datos personales vigente para garantizar la confidencialidad y seguridad de la información de los Usuarios de la plataforma.</li>
                </ul>
                <h6 class="heading-5">Contenido:</h6>
                <p class="para-p">El Establecimiento Comercial y el Freelancer se comprometen a compartir con Buki determinado contenido y materiales que le será requerido con la finalidad de poder promocionar adecuadamente sus servicios a través de la plataforma Buki. Por lo tanto, deberá facilitar sin demora la información que le sea requerida para este propósito.</p>
                <p class="para-p">En caso Buki advierta de que el contenido compartido por el Establecimiento Comercial o el Freelancer en la plataforma digital no cumple con las garantías previstas en los presentes TyC, procederá a eliminarlo de forma inmediata y adoptar las medidas correctivas que considere necesarias.</p>
                <h6 class="heading-5">Licencia de uso:</h6>
                <p class="para-p">Con la aceptación de los presentes términos y condiciones el Establecimiento Comercial y el Freelancer conceden a Buki una licencia de uso no exclusiva, libre de regalías, irrevocable y a nivel global para los siguientes actos: </p>
                <ol type="a">
                    <li>Usar, reproducir, publicitar, distribuir, sublicenciar, comunicar y poner a disposición el contenido asociado a la plataforma Buki, los perfiles de redes sociales y los canales de distribución para cualquiera de los fines previstos en los presentes términos y condiciones. </li>
                    <li>Modificar o eliminar cualquier de los contenidos asociados que sean publicados en la plataforma Buki por cualquier motivo; y,</li>
                    <li>Para la optimización de motores de búsqueda, publicidad de pago y otros servicios que utilicen el nombre comercial o marca del Establecimiento Comercial o el Freelancer asociada a los servicios ofrecidos por la plataforma Buki.</li>
                </ol>
                <h6 class="heading-5">Propiedad intelectual:</h6>
                <p class="para-p">Sin perjuicio de las obligaciones asumidas mediante la suscripción de los presentes términos y condiciones, Buki conservará en todo momento todos los derechos sobre el software de afiliados, la plataforma Buki y sobre su contenido (incluidas las reseñas de los Usuarios), así como los demás derechos de propiedad intelectual que le pertenezcan o sobre los que tenga licencia. Ninguna de las disposiciones plasmada en los presentes términos y condiciones otorga derecho al Establecimiento Comercial o al Freelancer sobre ninguno de los bienes de propiedad intelectual de Buki.</p>
                <p class="para-p">Buki se reserva el derecho de modificar a su entera discreción el formato, contenido y estilo de las páginas de los Establecimientos Comerciales o de los Freelancers, fotos y menús para mejorar la plataforma Buki y su contenido. </p>
                <h6 class="heading-5">Vigencia, Rescisión y Suspensión:</h6>
                <p class="para-p">Los presentes términos y condiciones comenzarán a regir a partir de que el Establecimiento Comercial o el Freelancer firman el Acuerdo de Afiliación y se encontrarán vigentes hasta que se produzca alguna de las causales de recisión previstas en el presente acápite.</p>
                <p class="para-p">Sin perjuicio de ello, las partes podrán rescindir los presentes términos y condiciones en cualquier momento y por cualquier motivo para lo cual deberán notificar por escrito a la otra parte con una anticipación no menor a 30 días calendario. Para dicho efecto, la voluntad de rescisión del Establecimiento Comercial o del Freelancer deberá ser comunicada a Buki por escrito al correo electrónico [*]. En el caso de Buki, su voluntad de rescisión será comunicada por escrito al correo electrónico de contacto provisto por el Establecimiento Comercial o el Freelancer al momento de su registro en la plataforma Buki. </p>
                <p class="para-p">Buki podrá rescindir los presentes términos y condiciones de manera unilateral e inmediata mediante notificación al Establecimiento Comercial o el Freelancer en los siguientes casos:</p>
                <ul class="decimal small" start="1">
                    <li>Incumplimiento parcial o total de cualquiera de los presentes términos y condiciones que no pueda ser subsanado o que no se subsane dentro de un plazo razonable</li>
                    <li>Incumplimiento reiterado de cualquiera de los presentes términos y condiciones.</li>
                    <li>Falta de pago de las comisiones y tarifas pactadas por el uso de la plataforma Buki.
                    </li>
                    <li>Por la realización de actos que afecten la reputación de la plataforma Buki o actos que en general desprestigien el sector belleza.</li>
                    <li>Uso ilegal del software de afiliados o de la plataforma Buki.</li>
                    <li>Comisión de actos ilegales y/o contrario al marco regulatorio de su actividad comercial.</li>
                    <li>Comisión de actos que causen o tenga el potencial de ocasionar un perjuicio al bienestar físico o mental de los Usuarios o clientes bajo su cuidado y/o supervisión. </li>
                    <li>Por comportamiento indebido incluyendo lenguaje, conducta inapropiada, abusiva, discriminatoria, ofensiva o agresión física o psicológica contra Usuarios, clientes o colaboradores de Buki.</li>
                    <li>Por infracción a los derechos de propiedad intelectual de Buki o de terceros o por la afectación a la confidencialidad y seguridad de datos personales.</li>
                    <li>Por la terminación o riesgo de terminación de su actividad comercial.</li>
                    <li>Cuando incurra en insolvencia, quiebra o haya suspendido los pagos a su cargo. </li>
                </ul>
                <p class="para-p">Asimismo, Buki podrá suspender a entera discreción y de manera inmediata el acceso del Establecimiento Comercial o del Freelancer a cualquiera de los servicios ofrecidos en el software de afiliados o en la plataforma Buki cuando exista sospecha de:</p>
                <ul class="decimal small" start="1">
                    <li>Intentar evadir el pago de comisiones y/o tarifas convenidas en el Acuerdo de Afiliación mediante la cancelación de Reservas de manera injustifcada.
                    </li>
                    <li>Cuando haya intervenido o influido para que un Usuario desista de tomar una Reserva agendada por la plataforma Buki para hacer una directamente con el establecimiento.</li>
                    <li>Consignar como “no presentada” una Reserva efectivamente prestada al Usuario..</li>
                    <li>No haber brindado los servicios de belleza de conformidad con los presentes términos y condiciones.</li>
                    <li>Haber publicitado información falsa o engañosa sobre sus servicios en la plataforma Buki.</li>
                    <li>Haber incumplido con las obligaciones relativas a las reseñas y calificaciones de los Usuarios.</li>
                    <li>Haber incumplido con las obligaciones relativas a la protección de datos personales.</li>
                    <li>Interactuar en línea con Usuarios o empelados de Buki de manera inapropiada.</li>
                    <li>Encontrarse involucrado en cualquier acto que desacredite públicamente la reputación de Buki o de cualquiera de sus afiliados.</li>
                    <li>Haber utilizado el software de terceros para gestionar las Reservas.</li>
                </ul>
                <p class="para-p">Buki no será responsable por la pérdida que pueda presentar el Establecimiento Comercial o el Freelancer durante el tiempo que no pueda acceder a los servicios del software de afiliados o a los de la plataforma Buki con motivo de lo antes señalado.</p>
                <h6 class="heading-5">Responsabilidad e Indemnización:</h6>
                <p class="para-p">El Establecimiento Comercial y el Freelancer reconocen que Buki es solo un intermediario y que no interviene en la celebración de las relaciones de consumo con los Usuarios. En consecuencia, el Establecimiento Comercial y el Freelancer son los únicos responsables por el incumplimiento de las condiciones de los servicios ofrecidos y efectivamente prestados a través de la plataforma Buki y/o por la idoneidad de los mismos.</p>
                <p class="para-p">En esa línea, sin que sea considerada una lista cerrada, el Establecimiento Comercial y el Freelancer reconocen que Buki no será responsable en los siguientes casos:</p>
                <ul class="decimal small" start="1">
                    <li>Cuando el servicio de belleza brindado haya comprometido la estética, salud, integridad del Usuario o de la persona para la cual se haya hecho la Reserva.</li>
                    <li>Cuando no cumplan con la fecha y/o con el horario exacto de la reserva.</li>
                    <li>Cuando no cumplan con destinar al estilista seleccionado por el Usuario para la atención de la Reserva.</li>
                    <li>Cuando no cumplan con las promociones y ofertas de sus propios servicios ofrecidos en la plataforma.</li>
                    <li>Por la calidad e idoneidad de los productos cosméticos y de belleza en general empleados para la atención de los Usuarios.</li>
                    <li>Por la falta de disponibilidad de los productos y/o recursos necesarios para brindar el servicio ofrecido.  </li>
                    <li>Por cualquier tipo de circunstancia relacionada a la atención bridada a los Usuarios por su propio personal. </li>
                    <li>Por la confidencialidad de la información personal que el Usuario les haya entregado de forma directa..</li>
                    <li>Por el incumplimiento de la regulación administrativa, municipal y/o sectorial que deban observar  con motivo de la prestación de sus servicios.</li>
                    <li>Por el equipamiento de las instalaciones empleados.</li>
                    <li>Por la atención de los reclamos y/o quejas relacionadas a los servicios que han brindado</li>
                </ul>
                <p class="para-p">Asimismo, el Establecimiento Comercial y el Freelancer reconocen y declaran que Buki no será responsable por la pérdida o daños que se hayan podido originar como consecuencia de:</p>
                <ul class="content-list small">
                    <li>El uso o incapacidad de uso del software de afiliados o de la plataforma Buki.</li>
                    <li>El acceso no autorizado a la cuenta del Establecimiento Comercial o del Freelancer.</li>
                    <li>Acciones de terceros contra los servicios del software de afiliados o la plataforma Buki.</li>
                    <li>Cualquier otra circunstancia que se encuentre relacionada a los servicios prestados por Buki.</li>
                </ul>
                <h6 class="heading-5">Cláusula de indemnidad </h6>
                <p class="para-p">Mediante la suscripción de los presentes términos y condiciones el Establecimiento Comercial y el Freelancer eximen de toda responsabilidad a Buki, así como a sus directivos, colaboradores, representantes, apoderados y/o empleados frente a cualquier tipo de pérdida, daños y perjuicios, sean directos o indirectos, que se hayan producido por:</p>
                <ul class="roman">
                    <li>El incumplimiento de los presentes términos y condiciones.</li>
                    <li>Cualquier reclamo, queja o denuncia o acción judicial de terceros relacionada a los servicios provistos por el propio Establecimiento Comercial o el Freelancer ; y,</li>
                    <li>Cualquier denuncia administrativa o acción judicial por infracción a la legislación de propiedad intelectual y a la de protección de datos personales. </li>
                </ul>
                <p class="para-p">En atención a ello, el Establecimiento Comercial o el Freelancer asumirá o en su defecto reembolsará a Buki las multas, sanciones y/o medidas correctivas, condena de pago de costas y costos, que le hayan sido impuestas, incluidos los gastos de defensa legal y los gastos asociados en los que haya incurrido, con motivo de cualquiera de las circunstancias antes descritas.</p>
                <h6 class="heading-5">Misceláneos:</h6>
                <ul class="content-list small">
                    <li><b>Confidencialidad:</b> Ambas partes se comprometen a mantener la confidencialidad de la información de la otra parte, a protegerla al mismo nivel con el que protegerían su propia información confidencial y a no compartirla con terceros, salvo con el consentimiento de la otra parte o cuando así lo exija la ley o la normativa.</li>
                    <li><b>Auditorías:</b> Buki se reserva el derecho de realizar auditorías periódicas con la finalidad de verificar de que el Establecimiento Comercial o el Freelancer cumple los presente términos y condiciones. Para dichos efectos, comunicará al Establecimiento Comercial o al Freelancer la programación de la auditoría con una anticipación no menor a 30 días calendario.</li>
                    <li><b>Soporte:</b> en caso de que el Establecimiento Comercial presente problemas con el uso del software de afiliados o la plataforma Buki podrá requerir soporte técnico a través del correo electrónico: soporte@bukiglobal.com [*] Buki se compromete a adoptar todas las medidas que a su consideración estime necesarias para resolver los impases comunicados en el menor tiempo posible.</li>
                    <li><b>Notificaciones:</b> todas las notificaciones que intercambien las partes con motivo de la aplicación de los presentes términos y condiciones deberán ser dirigida por escrito a las direcciones de correo electrónico convenidas por las partes o las direcciones especificadas en el presente documento.</li>
                    <li><b>Modificaciones:</b> Buki se reserva el derecho de modificar los alcances de los presentes términos y condiciones los cuales será comunicados al Establecimiento Comercial o al Freelancer con una anticipación no menor a 30 días calendario antes de su entrada en vigencia. En caso de que el Establecimiento Comercial o el Freelancer no haya manifestado su decisión de no continuar la relación dentro del plazo antes señalado se entenderá que se encuentra de acuerdo con dichos cambios y se encontrará obligado a su cabal cumplimiento.</li>
                    <li><b>Cesión:</b> Ni el Establecimiento Comercial ni el Freelancer podrán ceder, subcontratar o transferir su posición contractual o los derechos y obligaciones derivados de los presentes términos y condiciones sin el consentimiento por escrito de Buki.</li>
                    <li><b>Integridad del Acuerdo:</b> los presente términos y condiciones, el Acuerdo de Afiliación, así como las políticas de privacidad y de cookies, constituyen la totalidad del acuerdo celebrado entre el Establecimiento Comercial, el Freelancer y Buki.</li>
                    <li><b>Ley aplicable y jurisdicció:</b> el presente acuerdo se interpretará de conformidad con las disposiciones de la legislación Peruana vigente y en lo no previsto serán de aplicación supletoria las disposiciones del Código Civil Peruano. Las partes convienen que ante cualquier discrepancia respecto a la aplicación e interpretación de los presentes términos y condiciones, buscarán dar solución al impase entre ellos observando la buena fe comercial. En el improbable caso de no llegar a un acuerdo por medio del trato directo, las partes renuncian al fuero de sus domicilios y convienen someterse a la jurisdicción de los jueces y tribunales del Distrito Judicial de Lima Metropolitana.</li>
                </ul>


        </div>
        <div class="row privacy" *ngIf="language1==2">
            <div class="col-sm-12">
                <div class="title mb-4">
                    <p class="header-2"> TERMS AND CONDITIONS: COMMERCIAL ESTABLISHMENTS AND FREELANCERS</p>
                </div>
               
                <h6 class="heading-5"> General Aspects</h6>
                <p class="para-purple">Buki Peluplanner S.A.C., identified with R.U.C. No. 20610927379, located at Av. Javier Prado Este No. 2248, district of San Borja, province and department of Lima, is a company incorporated under the laws of the Republic of Peru and the owner of this digital platform, which will henceforth be referred to as “Buki”.</p>
            </div>
        </div>
        
        <div class="col-sm-12" *ngIf="language1==2">
            <h6 class="heading-5"> Definitions</h6>
            <p class="para-p">In the interpretation and application of these terms and conditions, the following definitions shall be considered:</p>
            <ul class="content-list small">
                <li><b>Affiliation Agreement:</b> a binding agreement governing the conditions of use of the Buki platform between Commercial Establishments, Freelancers, and Buki.</li>
                <li><b>Access Data:</b> username and password used for registration and login to the user's account on the affiliate software.</li>
                <li><b>Review Guidelines:</b> a document containing guidelines for the exchange of communications between Commercial Establishments or Freelancers and Users of the Buki platform in relation to comments and ratings given to the services offered by these.</li>
                <li><b>Commercial Establishment:</b> beauty center affiliated with the Buki platform for offering beauty, aesthetic, and spa services to Users.</li>
                <li><b>Freelancer:</b> beauty professional or stylist affiliated with the Buki platform who provides beauty and/or aesthetic services independently to Users.</li>  
               
                <li><b>Registration:</b> process by which an individual enters their personal information into the virtual form to create an account on the Buki platform.</li>
        
                <li><b>Service:</b> intermediary work performed by the digital platform to facilitate the consumer relationship between Users and beauty centers affiliated with the platform.</li>
                <li><b>Service Order:</b> request made by the User regarding a service provided by a beauty center or Freelancer after payment has been confirmed.</li>
                <li><b>User:</b> natural person who has registered an account on the Buki platform.</li>
            </ul>
            <h6 class="heading-5">Platform Operation</h6>
        
            <p class="para-p">The Buki platform is a technological tool designed to facilitate intermediation between Users and Commercial Establishments or Freelancers in order for the former to hire services offered by the latter. Accordingly, the user acknowledges that Buki always acts as a third-party intermediary not involved in the consumer relationships established between Users and Commercial Establishments or Freelancers.</p>
            <h6 class="heading-5">Service Conditions:</h6>
            <p class="para-p">The Buki platform offers various services to Commercial Establishments and Freelancers that allow them to manage their appointments with platform Users and promote their different services through the same. The services contracted with the Buki platform will be defined in the Affiliation Agreement signed between the Commercial Establishment or Freelancer and Buki.</p>
            <h6 class="heading-5">Services:</h6>
            <p class="para-p">The Buki platform provides Commercial Establishments and Freelancers with primarily two services whose conditions are specified in the Affiliation Agreement:</p>
            <ul class="content-list small">
                <li><p class="para-p"><b>Affiliate Software:</b> consists of appointment management software with various functions to enhance schedule management with clients and boost business development. The affiliate software includes point-of-sale functions and tools that the Commercial Establishment or Freelancer can use to increase their commercial reach with Users of the Buki platform.</p>
                <p class="para-p">If the Commercial Establishment or Freelancer have acquired affiliate software, a personal, exclusive, non-transferable, and revocable use license will be granted to use the software in managing their business. Buki reserves the right to monitor the use of the affiliate software through admin access to provide support and identify opportunities for improvement in its development.</p>
                <p class="para-p">The Commercial Establishment or Freelancer may create an account in the affiliate software and must generate their own access data under a username and password to access it. The created account will be personal and non-transferable, and it is the duty of the Commercial Establishment and Freelancer not to share the access data with third parties who have not acquired the affiliate software with Buki.</p>
                <p class="para-p">Buki offers no warranties regarding the features, functionality, and performance of the affiliate software, nor regarding the accuracy of the information or the results obtained through it. Similarly, Buki is not responsible for any losses that Commercial Establishments or Freelancers may suffer due to failures or interruptions in the salon software service.</p>
                <p class="para-p">Notwithstanding this, Buki commits to taking measures reasonably within its control to improve the functionality levels of the affiliate software should it not reach an adequate standard for the function to be fulfilled.</p>
            </li>
            <li><p class="para-p"><b>Buki Platform:</b> a virtual booking platform for appointments with beauty centers or professionals, located on our Buki App, where Commercial Establishments or Freelancers can advertise their beauty services to a large number of potential clients who download the app directly to their smartphones or smart devices.</p></li>
            </ul>  
            <h6 class="heading-5">Commissions and Fees:</h6>  
            <p class="para-p">The Commercial Establishment or Freelancer will pay Buki a commission for the Service provided through the use of the Buki platform to promote their beauty services to Users. The commission charged by Buki for this Service will be collected directly from the amount paid by the User at the time of making a Reservation through the Buki platform.</p>
            <p class="para-p">The amount of the commission, as well as the form and timing of payment, are detailed in the Affiliation Agreement signed with the Commercial Establishment or Freelancer, which may be reviewed and modified by Buki periodically. For these purposes, the Affiliation Agreement will have the same binding effect as these terms and conditions signed by the Commercial Establishment or Freelancer.</p>
            <p class="para-p">The commission that the Commercial Establishment or Freelancer pays to Buki for the Service does not include the payment to be debited for the use of the payment gateway and that will be charged directly by the service provider according to the conditions of its service contract.</p>
            <p class="para-p">The Commercial Establishment or Freelancer will be responsible for charging and collecting the General Sales Tax (18%) on the total value of the Reservation managed through the Buki platform. Likewise, the Commercial Establishment or Freelancer commit to attend any type of information requirement and/or audit conducted by tax or fiscal authorities in relation to the services offered through the Buki platform.</p>
            <p class="para-p">Buki may require the Commercial Establishment or Freelancer copies of the payment receipts corresponding to the services provided through the Buki platform, of the declarations and payment certificates corresponding to the taxes paid by them and/or any tax exemption certificate as applicable. </p>
            <p class="para-p">The Commercial Establishment or Freelancer will be responsible for refunds, surcharges, bank and/or administrative expenses as applicable in case they have not informed Buki in due time about changes in their payment and/or bank data if applicable.</p>
            <p class="para-p">In the event that Buki detects that the Commercial Establishment or Freelancer have attempted to evade the payment of any commission fraudulently, Buki reserves the right to demand and/or withhold any payment due. Notwithstanding this, Buki may suspend, cancel, disable the account of the Commercial Establishment or Freelancer at its sole discretion until compliance with the payment of the amounts owed is verified.</p>
            <p class="para-p">Buki reserves the right to modify at any time the amount of its commissions and fees which will be communicated to the Commercial Establishment or Freelancer with 30 calendar days of anticipation. Once the aforementioned period has passed without the Commercial Establishment or Freelancer having expressed their willingness to dispense with the services of Buki, it will be understood that they agree with the modifications to the commissions and fees, being obligated to pay them at the opportunities agreed in the Affiliation Agreement.</p>
            <h6 class="heading-5">Reservations and Cancellations:</h6>
            <p class="para-p">Users may schedule an appointment with the Commercial Establishments or Freelancers to receive the beauty services offered on the Buki platform, with each of these appointments constituting a Reservation for the purposes of interpreting these terms and conditions.</p>
            <p class="para-p">When the Commercial Establishment or Freelancer accept a User's Reservation, they commit to providing the beauty services under the same conditions as they have been promoted and advertised through the Buki platform. The Commercial Establishments and Freelancers will be solely responsible for providing the beauty services in the manner, mode, and timing as they have been offered to Users.</p>
            <p class="para-p">The Commercial Establishments and Freelancers will accept Reservations made through the Buki platform based on the availability of their personnel and resources. Nevertheless, they commit to making all necessary efforts to accept as many Reservations as possible made by Users through the Buki platform.</p>
            <p class="para-p">Buki will not refund the User in cases where the cancellation of the Reservation has occurred within 24 hours prior to the time set for the Reservation, with the Service Commission and the corresponding charge for the use of the payment gateway being debited. In this case, Buki will transfer to the Commercial Establishment and/or Freelancer the amount corresponding to the charge for the contracted service so that under its own cost and responsibility it carries out the corresponding refund for the beauty service contracted with the Users. However, Buki will refund the User the total amount paid for the Reservation when the cancellation of the same is made with more than 24 hours notice from the time set for the Reservation.</p>
            <p class="para-p">Notwithstanding the aforementioned, at any time, the Commercial Establishments and Freelancers will have the possibility to negotiate directly and voluntarily with Users the cancellation and refund of a Reservation in accordance with their own commercial policies. In such a case, the Commercial Establishment or Freelancer will assume under its own responsibility the refund of the amount corresponding to its services, discounting the Service Commission of Buki and the amount corresponding to the payment gateway service.</p>
            <h6 class="heading-5">Reviews and Ratings:</h6>
            <p class="para-p">The Buki platform provides the possibility for Users to search for the services offered by Commercial Establishments and Freelancers through a listing that contains the names of affiliated establishments. The search and classification of Commercial Establishments and Freelancers respond to various factors that allow Users to find the beauty services that best suit their needs. Users will have the possibility to search for beauty services through filters with various search criteria that allow them to optimize the expected results.</p>
            <p class="para-p">The Buki platform grants the possibility for Users to leave reviews about the services provided by Commercial Establishments and Freelancers which they can access permanently. The Commercial Establishment and Freelancer exempt Buki from any liability for any loss that may have occurred due to the reviews and ratings that Users make about each of their services.</p>
            <p class="para-p">The Commercial Establishment and Freelancer will not maintain any rights over the reviews, ratings, and/or comments of Users of the Buki platform nor will they have the right to request a partial or total copy of these.</p>
            <p class="para-p">The Commercial Establishment and Freelancer may respond to the comments and opinions that Users provide about their beauty services in accordance with the Review Guidelines which they can access after their affiliation to the Buki platform.</p>
            <p class="para-p">Buki reserves the right to ask the Commercial Establishment or Freelancer to review and/or modify their responses to Users before or after publishing them. Notwithstanding this, Buki reserves the right to remove any response to Users that does not comply with the provisions of the Review Directive.</p>
            <p class="para-p">In the event that the Commercial Establishment or Freelancer considers a comment or review by a User about their establishment, professional personnel, or any of their services to be defamatory or even potentially violating their rights or those of third parties, they may communicate such circumstance to Buki for investigation and evaluation of the case. After the investigation is carried out, Buki will adopt the measures it deems necessary to correct the situation which may include the removal of the User's comment from the platform.</p>
            <h6 class="heading-5">Payment Gateway:</h6>
            <p class="para-p">An external provider will provide the payment gateway services used by the Buki platform to process the payment for the beauty services offered by the Commercial Establishments and Freelancers.</p>
            <p class="para-p">By accepting these terms and conditions, the Commercial Establishments and Freelancers grant their authorization for the fee set for the payment gateway service to be discounted and debited from the total amount of the Reservation which will be charged directly by the provider in charge of providing this service.</p>
            <p class="para-p">The Commercial Establishments and Freelancers commit to complying with all the conditions of the payment gateway service to facilitate the management of Reservations on the Buki platform.</p>
            <p class="para-p">If necessary, the Commercial Establishments and Freelancers commit to providing Buki with all the information about their business that is required and authorize Buki to share it, in order to manage the enablement of the payment gateway services associated with their account.</p>
            <h6 class="heading-5">Obligations of the Commercial Establishment and/or Freelancer:</h6>
            <p class="para-p">Through the acceptance of these terms and conditions, the Commercial Establishment and/or Freelancer voluntarily, expressly, and irrevocably commit to complying with the following obligations:</p>
            <ul class="small decimal">
                <li>They may not sublicense the license to use the affiliate software to third parties nor transfer, assign, or sell it.</li>
                <li>They will maintain the confidentiality of their access data to the affiliate software such as their username and password, being prohibited from transmitting or assigning them to third parties. Furthermore, they will take all reasonable actions to prevent any unauthorized access and in the event that it occurs, take measures to repel it. Notwithstanding this, they must communicate to Buki immediately in case they become aware of the existence or attempt of unauthorized access.</li>
                <li>They will keep the data of their represented entity registered in their account up to date when these are modified.</li>
                <li>They may not access the affiliate software through data corresponding to other Commercial Establishments or Freelancers.</li>
                <li>They will not manipulate or tamper with the affiliate software by introducing viruses, software, programs, or through any technological or analogous method for illicit or fraudulent purposes that may cause damage and/or affect its normal functioning.</li>
                <li>They will ensure that their trade name, address, and other identification data are fully communicated to Users of the Buki platform in all the services they use.</li>
                <li>They will not provide Users with contact data, indirect references, or links to platforms external to Buki.</li>
                <li>They will guarantee that all information, documentation, photographs, contact data, brand guidelines, cancellation policies, service conditions, and prices are accurate, correct, up-to-date, and not misleading to Users.</li>
                <li>They will offer the services on the Buki platform at the best available price that they offer through their website. Notwithstanding this, the Commercial Establishment or Freelancer may offer lower prices and/or special offers to closed groups (such as loyalty programs) or directly at their establishment.</li>
                <li>They will obtain the consent of Users for the sending of commercial advertising, promotions, and offers and keep a record of such consents to share them if required by Buki.</li>
                <li>They will not ask Users to make reservations through any means other than the Buki platform or ask them to cancel a reservation to make a new one directly with the Commercial Establishment or Freelancer.</li>
                <li>They will periodically make backup copies of their information when using the affiliate software. Buki does not guarantee that all data entered into the affiliate software is backed up. Consequently, Buki will not be responsible for the loss of information that may have occurred by omitting this obligation.</li>
                <li>They may not use the affiliate software for illicit or illegal purposes that may involve the infringement of third-party rights.</li>
                <li>They will provide Buki with the information required about the nature, characteristics, and conditions of the services to be offered through the platform, as well as that related to the beauty professionals of their establishments with whom Users will schedule their appointments.</li>
                <li>They will fulfill the provision of beauty services requested through the Buki platform by Users in a complete and timely manner.</li>
                <li>They will fully comply with any offer or promotion made through the Buki platform according to the advertised terms and conditions.</li>
                <li>They will provide Buki platform Users with their cancellation and refund policy regarding the services offered through it.</li>
                <li>They will maintain a detailed record of each and every one of the Reservations managed through the Buki platform.</li>
                <li>They will provide Buki with the information required regarding any of the Reservations managed through the platform.</li>
                <li>They will collaborate with Buki regarding the investigation of any suspicious or irregular transactions that may have occurred in the use of the platform.</li>
                <li>They will fully and timely comply with the payment of the fees and/or commissions provided for in the Affiliation Agreement.</li>
                <li>They will take measures reasonably within their control to provide a suitable service to Users of the Buki platform.</li>
            </ul>
            <h6 class="heading-5">Guarantees:</h6>
            <p class="para-p">By accepting these terms and conditions, the Commercial Establishment and Freelancer declare and guarantee the following:</p>
            <ul class="content-list small">
                <li>That all the content they share and/or publish on the Buki platform is accurate and up-to-date, is not illegal, defamatory, offensive, or contrary to good customs and does not infringe the rights of third parties.</li>
                <li>That they comply with all sector-specific laws and regulations provided for the provision of their services and commit to comply with those that may come into force during their collaboration with the Buki platform.</li>
                <li>That they commit to complying with the regulation on commercial advertising and consumer protection in force, its amendments and substitutive texts in the promotion and execution of the services offered through the Buki platform.</li>
                <li>That they comply with tax regulations regulating their commercial activity.</li>
                <li>That they have all the licenses, permits, and authorizations issued by competent authorities for the development of their commercial activity.</li>
                <li>That they comply with the legislation on the protection of personal data in force to guarantee the confidentiality and security of the information of Users of the platform.</li>
            </ul>
            <h6 class="heading-5">Content:</h6>
            <p class="para-p">The Commercial Establishment and Freelancer commit to sharing with Buki certain content and materials that will be required to properly promote their services through the Buki platform. Therefore, they must provide without delay the information required for this purpose.</p>
            <p class="para-p">In case Buki notices that the content shared by the Commercial Establishment or Freelancer on the digital platform does not comply with the guarantees provided in these T&Cs, it will proceed to remove it immediately and adopt the corrective measures it deems necessary.</p>
            <h6 class="heading-5">License of Use:</h6>
            <p class="para-p">With the acceptance of these terms and conditions, the Commercial Establishment and Freelancer grant Buki a non-exclusive, royalty-free, irrevocable, and global license for the following acts:</p>
            <ol type="a">
                <li>To use, reproduce, advertise, distribute, sublicense, communicate, and make available the content associated with the Buki platform, social media profiles, and distribution channels for any of the purposes provided in these terms and conditions.</li>
                <li>To modify or delete any of the associated contents that are published on the Buki platform for any reason; and,</li>
                <li>For the optimization of search engines, paid advertising, and other services that use the trade name or brand of the Commercial Establishment or Freelancer associated with the services offered by the Buki platform.</li>
            </ol>
            <h6 class="heading-5">Intellectual Property:</h6>
            <p class="para-p">Notwithstanding the obligations assumed through the subscription of these terms and conditions, Buki will retain at all times all rights over the affiliate software, the Buki platform, and its content (including User reviews), as well as other intellectual property rights that belong to it or over which it has a license. None of the provisions set forth in these terms and conditions grants the Commercial Establishment or Freelancer any rights over any of Buki's intellectual property assets.</p>
            <p class="para-p">Buki reserves the right to modify at its entire discretion the format, content, and style of the pages of the Commercial Establishments or Freelancers, photos, and menus to improve the Buki platform and its content.</p>
            <h6 class="heading-5">Validity, Termination, and Suspension:</h6>
            <p class="para-p">These terms and conditions will begin to govern from the moment the Commercial Establishment or Freelancer sign the Affiliation Agreement and will remain in force until the occurrence of any of the termination causes provided in this section.</p>
            <p class="para-p">Notwithstanding this, the parties may terminate these terms and conditions at any time and for any reason for which they must notify the other party in writing with a notice period of no less than 30 calendar days. For this purpose, the will to terminate the Commercial Establishment or Freelancer must be communicated to Buki in writing at the email address [*]. In the case of Buki, its will to terminate will be communicated in writing to the contact email provided by the Commercial Establishment or Freelancer at the time of their registration on the Buki platform.</p>
            <p class="para-p">Buki may terminate these terms and conditions unilaterally and immediately by notifying the Commercial Establishment or Freelancer in the following cases:</p>
            <ul class="decimal small" start="1">
                <li>Partial or total non-compliance with any of these terms and conditions that cannot be remedied or that is not remedied within a reasonable period</li>
                <li>Repeated non-compliance with any of these terms and conditions.</li>
                <li>Lack of payment of the commissions and fees agreed for the use of the Buki platform.
                </li>
                <li>For carrying out acts that affect the reputation of the Buki platform or acts that generally disparage the beauty sector.</li>
                <li>Illegal use of the affiliate software or the Buki platform.</li>
                <li>Commission of illegal acts and/or contrary to the regulatory framework of their commercial activity.</li>
                <li>Commission of acts that cause or have the potential to cause harm to the physical or mental well-being of Users or clients under their care and/or supervision.</li>
                <li>For improper behavior including language, inappropriate, abusive, discriminatory, offensive conduct or physical or psychological aggression against Users, clients, or collaborators of Buki.</li>
                <li>For infringement of intellectual property rights of Buki or third parties or for affecting the confidentiality and security of personal data.</li>
                <li>For the termination or risk of termination of their commercial activity.</li>
                <li>When they incur insolvency, bankruptcy or have suspended payments on their account.</li>
            </ul>
            <p class="para-p">Likewise, Buki may suspend at its entire discretion and immediately the access of the Commercial Establishment or Freelancer to any of the services offered in the affiliate software or on the Buki platform when there is suspicion of:</p>
            <ul class="decimal small" start="1">
                <li>Attempting to evade the payment of commissions and/or fees agreed in the Affiliation Agreement by unjustifiably canceling Reservations.
                </li>
                <li>When they have intervened or influenced a User to desist from taking a Reservation scheduled by the Buki platform to make one directly with the establishment.</li>
                <li>Recording as "not presented" a Reservation effectively provided to the User..</li>
                <li>Not having provided the beauty services in accordance with these terms and conditions.</li>
                <li>Having advertised false or misleading information about their services on the Buki platform.</li>
                <li>Having breached the obligations related to reviews and ratings of Users.</li>
                <li>Having breached the obligations related to the protection of personal data.</li>
                <li>Interacting online with Users or Buki employees inappropriately.</li>
                <li>Being involved in any act that publicly discredits the reputation of Buki or any of its affiliates.</li>
                <li>Having used third-party software to manage Reservations.</li>
            </ul>
            <p class="para-p">Buki will not be responsible for the loss that the Commercial Establishment or Freelancer may present during the time they cannot access the services of the affiliate software or those of the Buki platform due to the aforementioned reasons.</p>
            <h6 class="heading-5">Responsibility and Indemnification:</h6>
            <p class="para-p">The Commercial Establishment and Freelancer acknowledge that Buki is only an intermediary and does not intervene in the celebration of consumer relationships with Users. Consequently, the Commercial Establishment and Freelancer are solely responsible for the non-compliance with the conditions of the services offered and effectively provided through the Buki platform and/or for the suitability of the same.</p>
            <p class="para-p">In this line, without being considered a closed list, the Commercial Establishment and Freelancer recognize that Buki will not be responsible in the following cases:</p>
            <ul class="decimal small" start="1">
                <li>When the beauty service provided has compromised the aesthetics, health, integrity of the User or the person for whom the Reservation was made.</li>
                <li>When they do not comply with the date and/or exact time of the reservation.</li>
                <li>When they do not allocate the stylist selected by the User for the attention of the Reservation.</li>
                <li>When they do not comply with the promotions and offers of their own services offered on the platform.</li>
                <li>For the quality and suitability of the cosmetic and beauty products in general used for the attention of Users.</li>
                <li>For the lack of availability of the products and/or resources necessary to provide the offered service.</li>
                <li>For any type of circumstance related to the attention provided to Users by their own personnel.</li>
                <li>For the confidentiality of the personal information that the User has given them directly..</li>
                <li>For non-compliance with the administrative, municipal and/or sectorial regulation that they must observe with regard to the provision of their services.</li>
                <li>For the equipment of the facilities used.</li>
                <li>For attending to the complaints and/or complaints related to the services they have provided</li>
            </ul>
            <p class="para-p">Likewise, the Commercial Establishment and Freelancer recognize and declare that Buki will not be responsible for the loss or damage that may have occurred as a consequence of:</p>
            <ul class="content-list small">
                <li>The use or inability to use the affiliate software or the Buki platform.</li>
                <li>Unauthorized access to the account of the Commercial Establishment or Freelancer.</li>
                <li>Actions by third parties against the services of the affiliate software or the Buki platform.</li>
                <li>Any other circumstance related to the services provided by Buki.</li>
            </ul>
            <h6 class="heading-5">Indemnity Clause</h6>
            <p class="para-p">By subscribing to these terms and conditions, the Commercial Establishment and Freelancer exempt Buki, as well as its directors, collaborators, representatives, attorneys, and/or employees from all liability for any type of loss, damages and injuries, whether direct or indirect, that have occurred due to:</p>
            <ul class="roman">
                <li>The breach of these terms and conditions.</li>
                <li>Any claim, complaint, or lawsuit or judicial action by third parties related to the services provided by the Commercial Establishment or Freelancer itself; and,</li>
                <li>Any administrative complaint or judicial action for infringement of intellectual property legislation and data protection legislation.</li>
            </ul>
            <p class="para-p">In this regard, the Commercial Establishment or Freelancer will assume or, failing that, reimburse Buki for the fines, sanctions and/or corrective measures, conviction of payment of costs and expenses, that have been imposed on them, including legal defense expenses and associated expenses incurred, due to any of the aforementioned circumstances.</p>
            <h6 class="heading-5">Miscellaneous:</h6>
            <ul class="content-list small">
                <li><b>Confidentiality:</b> Both parties commit to maintaining the confidentiality of the other party's information, to protect it at the same level with which they would protect their own confidential information and not to share it with third parties, except with the consent of the other party or when required by law or regulation.</li>
                <li><b>Audits:</b> Buki reserves the right to conduct periodic audits to verify that the Commercial Establishment or Freelancer complies with these terms and conditions. For these purposes, it will communicate the audit schedule to the Commercial Establishment or Freelancer with a notice of no less than 30 calendar days.</li>
                <li><b>Support:</b> in case the Commercial Establishment has problems with the use of the affiliate software or the Buki platform, they may require technical support through the email: soporte@bukiglobal.com [*] Buki commits to adopting all measures it deems necessary to resolve the reported issues in the shortest possible time.</li>
                <li><b>Notifications:</b> all notifications exchanged by the parties for the application of these terms and conditions must be directed in writing to the email addresses agreed by the parties or the addresses specified in this document.</li>
                <li><b>Modifications:</b> Buki reserves the right to modify the scope of these terms and conditions which will be communicated to the Commercial Establishment or Freelancer with a notice of no less than 30 calendar days before they come into effect. In case the Commercial Establishment or Freelancer has not expressed their decision to discontinue the relationship within the aforementioned period, it will be understood that they agree with such changes and will be obligated to fully comply with them.</li>
                <li><b>Assignment:</b> Neither the Commercial Establishment nor the Freelancer may assign, subcontract or transfer their contractual position or the rights and obligations derived from these terms and conditions without the written consent of Buki.</li>
                <li><b>Integrity of the Agreement:</b> these terms and conditions, the Affiliation Agreement, as well as the privacy and cookie policies, constitute the entirety of the agreement entered into between the Commercial Establishment, the Freelancer, and Buki.</li>
                <li><b>Applicable Law and Jurisdiction:</b> this agreement will be interpreted in accordance with the provisions of the current Peruvian legislation and in the absence of provisions, the provisions of the Peruvian Civil Code will be applicable as a supplementary measure. The parties agree that in the event of any discrepancy regarding the application and interpretation of these terms and conditions, they will seek to resolve the impasse between them observing commercial good faith. In the unlikely event that an agreement is not reached through direct negotiation, the parties renounce the jurisdiction of their domiciles and agree to submit to the jurisdiction of the judges and courts of the Judicial District of Metropolitan Lima.</li>
            </ul>
        
        
        </div>
        
                      
                   </div>
                </div>    
              
            </div> 
             </div>
    </div>
</div>