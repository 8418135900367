import { Component } from "@angular/core";
import { NavigationEnd, Router, ActivatedRoute } from "@angular/router";
import { MessagingService } from './services/messaging.service';
import { DataSharingService } from './services/data.share.service';
import { I18nService } from './services/i18n.service'
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { apiUrl } from 'src/app/global/global';
import { AdminService } from 'src/app/services/admin.service';

import { LocaleService } from './services/locale.service';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "buki";
  message:any;
  isNew:any;
  constructor(private localeService: LocaleService,
    @Inject(PLATFORM_ID) private platformId: any,private router: Router,private api: AdminService,  private dataSharingService: DataSharingService, private messagingService: MessagingService, private languge :I18nService, private active: ActivatedRoute) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      // window.scrollTo({ top: 0, behavior: 'smooth' });
    });
    
    if (isPlatformBrowser(this.platformId)) {
    var observer = new MutationObserver( (event) => {
      if(document.documentElement.className.match('translated')) {
        console.log(document.documentElement.lang)
        if(document.documentElement.lang=='es'){
          localStorage.setItem('language', "1");
          console.log('sp')
          this.languageChange(1)
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = "reload";
          // this.router.navigate(["/account-settings"]);
          window.location.reload();
        }
        
        if(document.documentElement.lang=='en'){
          localStorage.setItem('language',"2");
          console.log('en')
          this.languageChange(2) 
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = "reload";
          // this.router.navigate(["/account-settings"]);
          window.location.reload();
        }
      }
    });
    
    observer.observe(document.documentElement, {
    attributes: true,
    attributeFilter: ['class'],
    childList: false,
    characterData: false
    });
  }
   
  }

  

  ngOnInit() {
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage;
    // console.log('mmmmmm', this.message)
    this.dataSharingService.currentLanguage.subscribe(language =>{
      this.languge.fetch(language)
    })
    let languages:any = localStorage.getItem('language')
    let languageSet =  languages == 1 ? 'sp' :  languages == 2 ? 'en' :'pt'
    
    let languageSet1 =  languages == 1 ? 'es' : 'en'
    this.localeService.initLocale(languageSet1);
    this.languge.fetch(languageSet)


  }
  languageChange(value:any){
    
    let data = {
      language: Number(value)
    }
    this.api.putData(apiUrl._updateProfile, data).subscribe(res => {
      
    let languageSet =  value == 1 ? 'sp' :  value == 2 ? 'en' :'pt'
      localStorage.setItem('language', languageSet);
      
    let languageSet1 =  value == 1 ? 'es' : 'en'
    const lang:any = this.localeService.currentLocale.startsWith(languageSet1);
    this.localeService.setLocale(lang);
    })
  }
  

}
