import { Injectable, ApplicationRef } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { delay, map, catchError } from 'rxjs/operators';
import pt from 'src/assets/i18n/pt.json';
import en from 'src/assets/i18n/en.json';
import sp from 'src/assets/i18n/sp.json';

import { Observable } from "rxjs";

@Injectable()
export class I18nService {

    public data: {};
    public currentLanguage: any;
    public configs: any;

    constructor(
        private http: HttpClient,
        private ref: ApplicationRef) {
        let language:any = localStorage.getItem('language')
        let laguageSet:any =  language == 1 ? 'sp' :  language == 2 ? 'en' :'pt'

        // this.setLanguage(laguageSet);
        this.fetch('sp')
    }

    public setLanguage(language) {
    
        this.currentLanguage = language;
        this.fetch(language)
    }

    public getTranslation(phrase: string, variableData: any=null): string {
       
        // console.log('pipe1')
        if(this.data && this.data[phrase]){
            if(!variableData){
                return this.data[phrase];
            }
            let str = this.data[phrase];
            Object.keys(variableData).forEach((value)=>{
                let replacevaule = "{"+value+"}";
                str = str.replace( new RegExp(replacevaule, 'g'), variableData[value]);
            })
            return str;
        }
        else 
            return phrase;
    }

    public fetch(locale: any) {
    
        if(locale == 'pt')
        {
            this.data = pt
        }else if(locale == 'sp'){
            this.data = sp

        }else{
            this.data = en
        }

        // let langFilePath = `assets/i18n/${locale}.json`;
        
        // console.log('langFilePath', langFilePath)
        // this.fetchLangFile(langFilePath)
        //     .subscribe((data: any) => {
        //         this.data = data;
        //         this.ref.tick();
        //     })
    }

    private fetchLangFile(url): Observable<any> {
        let baseurl=location.protocol+'//'+location.hostname+(location.port? ':'+location.port:'')+'/';
        return this.http.get(baseurl+ url)
            .pipe(
                map((data: any) => (data.data || data))
            );
    }

}