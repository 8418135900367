import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { AdminService } from "./admin.service";
import { DataSharingService } from "./data.share.service";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  language:any;

  constructor(private admin: AdminService,
    private localStorage: DataSharingService,
    private router: Router
  ) {

    let language:any =localStorage.getItem('language')
    this.language=language==1 ? 1 : language== 2 ? 2:3
   }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    //let token = "Bearer "+ localStorage.getItem("adminToken");

    const token: any = this.localStorage.getItem('accessInfo')
      ? JSON.parse(this.localStorage.getItem('accessInfo') || '')
      : "";
    // console.log(token.accessToken)
    if (token && !req.url.includes('.pdf')) {
      req = req.clone({ headers: req.headers.set("authorization", token.accessToken) });
    }

    if (this.admin.showLoader) this.admin.loader(true);
    else this.admin.showLoader = true;

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.admin.loader(false);
          }
        },
        (err: any) => {
          this.admin.loader(false);
          let language:any =localStorage.getItem('language')
          let languageSet=language== 1?1:language==2?2:3
          // console.log('....', err.error.statusCode)
          // console.log('....', err)
          if (err instanceof HttpErrorResponse) {
            if (err.error.statusCode == 401 && ((err.error.message == 'You have been logged out' && this.language == 2) || (err.error.message == 'Has sido desconectado' && this.language == 1) || (err.error.message == 'Você foi desconectado' && this.language == 3) || (err.error.message == 'Your are unauthorized. Please login or create account to get access.' && this.language == 2) || (err.error.message == 'No estás autorizado. Inicie sesión o cree una cuenta para obtener acceso.' && this.language == 1) || (err.error.message == 'Você não está autorizado. Faça login ou crie uma conta para obter acesso.' && this.language == 3) || (err.error.message == 'Your account has been blocked by the Admin. Please contact support@support.com.' && this.language == 2) || (err.error.message == 'El administrador ha bloqueado su cuenta. Por favor póngase en contacto con support@support.com.' && this.language == 1) || (err.error.message == 'Sua conta foi bloqueada pelo administrador. Entre em contato com support@support.com.' && this.language == 3) || (err.error.message == 'The token is not valid or User not Found!' && this.language == 2) || (err.error.message == 'El token no es válido o el usuario no fue encontrado!' && this.language == 1) || (err.error.message == 'O token não é válido ou Usuário não encontrado!' && this.language == 3))) {
              this.localStorage.clear();
              // this.admin.logOut();
              this.router.navigate(["/login"]);
            }
            else if (err.error.statusCode == 403) {
              this.admin.errorAlert(err.error.message, 403, languageSet)
            }
            else if (err.error.statusCode == 408) {
              this.admin.errorAlert(err.error.message, 408, languageSet)
            }
            else if (err.error.statusCode == 401 || err.error.statusCode == 405) {
              this.admin.errorAlert(err.error.message, 401, languageSet)
            } 
            else if(err.error.statusCode == 400 && ((err.error.message == 'This url has been expired.' && this.language==2) || (err.error.message == 'Esta URL ha caducado.' && this.language==1) || (err.error.message == 'Este URL expirou.' && this.language==3) )){
              let token: any = this.localStorage.getItem('accessInfo')
              ? JSON.parse(this.localStorage.getItem('accessInfo') || '')
              : "";
             
              if(token.accessToken){
                this.admin.errorAlertmsg(
                  this.language==2?"Please logout the main account than login with another":this.language==1?'Por favor cierre sesión en la cuenta principal y luego inicie sesión con otra':'Saia da conta principal e faça login com outrar', 
                
                400)

              }else{
                this.admin.errorAlertmsg(
                this.language==2?"This url has been expired.":this.language==1?'Esta URL ha caducado.':'Este URL expirou.', 
                
                400)
                this.router.navigate(["/login"]);
              }

            }else if (err.error.statusCode == 400) {
              if(err.error.message == 'Payment already initiated.' || err.error.message == 'Booking not found.' || err.error.message == 'Something went wrong.' ){

              }else{
  
                this.admin.errorAlert(err.error.message, 400, languageSet)
              
              }

              
            } 
            else{

            }

          }
        }
      )
    );
  }
}
