import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, } from "@angular/core";
import { BrowserModule, BrowserTransferStateModule} from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import {Component, enableProdMode, Inject, PLATFORM_ID} from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
/////service===
import { InterceptorService } from "./services/interceptor.service";
import { environment } from "src/environments/environment";
import { AsyncPipe,registerLocaleData} from "@angular/common";
import { DataSharingService } from "./services/data.share.service";
import { ServiceWorkerModule } from "@angular/service-worker";

//npm package===

import { ToastrModule } from "ngx-toastr";
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

/////Component
import { LoaderComponent } from "./common/loader/loader.component";
import { InternalComponent } from "./internal/internal.component";
import { MobileComponent } from './../app/extrnal/mobile/mobile.component'
import { MobilePrivacyComponent } from './../app/extrnal/mobile-privacy/mobile-privacy.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxPaginationModule } from "ngx-pagination";
import { DeeplinkService } from "./services/deeplink.service";

import { MessagingService } from './services/messaging.service';

import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule, AngularFireList, AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { SumPipeModule } from '../app/services/sum.pipe';
import { I18nService } from './services/i18n.service';
import { MobileAboutComponent } from "./extrnal/mobile-about/mobile-about.component";
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PaymentMobileComponent } from "./extrnal/payment-mobile/payment-mobile.component";
import { PaymentSuccessComponent } from './extrnal/payment-success/payment-success.component';
import { MobileFaqComponent } from "./extrnal/mobile-faq/mobile-faq.component";
import { PaymentErrorComponent } from "./extrnal/payment-error/payment-error.component";
import { PaymentWalletMobileComponent } from "./extrnal/payment-wallet-mobile/payment-wallet-mobile.component";

import { WebPrivacyComponent } from './../app/extrnal/web-privacy/web-privacy.component';
import { WebTermsComponent } from './../app/extrnal/web-terms/web-terms.component';

import { TranslateModule } from '@ngx-translate/core';
import { LocaleProvider } from './services/locale.provider';
import localeES from '@angular/common/locales/es';
import { DeleteContantComponent } from './extrnal/delete-contant/delete-contant.component'
registerLocaleData(localeES, 'es');

enableProdMode();

@NgModule({
  declarations: [
    AppComponent,
    
    LoaderComponent,
    InternalComponent,
    MobileComponent,
    MobileAboutComponent,
    MobilePrivacyComponent,
    MobileFaqComponent,
    PaymentMobileComponent,
    PaymentSuccessComponent,
    PaymentErrorComponent,
    PaymentWalletMobileComponent,
    WebTermsComponent,
    WebPrivacyComponent,
    DeleteContantComponent
  ],
  imports: [
  
    SumPipeModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    ServiceWorkerModule,
    NgxPaginationModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 2500,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    TranslateModule.forRoot(),
   


  ],
  providers: [
    LocaleProvider,
    I18nService,
    AsyncPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    DataSharingService,
    MessagingService,
    DeeplinkService,
    SumPipeModule,
  

  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule { }
