import { Component, OnInit } from "@angular/core";
import { AdminService } from "../../services/admin.service";

import { I18nService } from '../../services/i18n.service'

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.css"],
})
export class LoaderComponent implements OnInit {
  spinner;
  language1:any;
  constructor(
    private admin: AdminService,private I18nService:I18nService
  ) {}

  ngOnInit(): void {
    let language:any =localStorage.getItem('language')
    this.language1=language== 1?1:language==2?2:3

    this.admin.loaderState.subscribe((data) => {
      this.spinner = true;
      if (data) {
        this.spinner = true;
      } else {
        this.spinner = false;
      }
    });
  }
  loader(){
    return this.I18nService.getTranslation('Loading', null)
  }
}
