import { Component, OnInit } from '@angular/core';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { apiUrl } from "../../global/global";
import { AdminService } from "../../services/admin.service";
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-mobile-about',
  templateUrl: './mobile-about.component.html',
  styleUrls: ['./mobile-about.component.css']
})
export class MobileAboutComponent implements OnInit {
  language:any='';
  text: any = "Loading...";
  aboutList;
  BaseUrl:any='';
  language1:any='';

  constructor(private api: AdminService, private location: Location,  private route: ActivatedRoute) { }

  ngOnInit(): void {
    let language:any =localStorage.getItem('language')
    this.language1=language== 1?1:language==2?2:3
    this.text = this.language1 == 2 ? "Loading..." : 
    this.language1 == 1 ? "Cargando" : 'Carregando...'

    this.BaseUrl = environment.BaseUrl + 'admin/';
    this.language = this.route.snapshot.paramMap.get('id');
    this.getAbout()
  }

  getAbout() {
    let params = new HttpParams();

    this.api.getDataWithoutLoader(apiUrl._getAbout, params).subscribe((res) => {
      if (res) {
      
        this.aboutList=res.data;
        // console.log('aboutList?.about_us_sections', this.aboutList?.about_us_sections)
        this.text = this.language1==2 ? 'No records found' : this.language1== 1 ? 'No se encontraron registros' :'Nenhum registro foi encontrado'
        
      }
    })
  }

  langugaeCheck(item){
    if(this.language == 1){
      return (item.title_spanish != null)?item.title_spanish:(item.title_english!=null)?item.title_english:item.title_portugese

    }else if(this.language == 2){
      return (item.title_english != null)?item.title_english:(item.title_spanish!=null)?item.title_spanish:item.title_portugese

    }else if(this.language == 3){
    return (item.title_portugese!=null)?item.title_portugese:(item.title_spanish!=null)?item.title_spanish:item.title_english
    }
  };
  langugaeCheck2(item){
    if(this.language == 1){
      return (item.description_spanish != null)?item.description_spanish:(item.description_english!=null)?item.description_english:item.description_portugese

    }else if(this.language == 2){
      return (item.description_english != null)?item.description_english:(item.description_spanish!=null)?item.description_spanish:item.description_portugese

    }else if(this.language == 3){
    return (item.description_portugese!=null)?item.description_portugese:(item.description_spanish!=null)?item.description_spanish:item.description_english
    }
  };

}
